<template>
    <div>
        <noProjects v-if="!myReferences.length && !refreshLoader"/>
        <div class="myReferences" v-if="myReferences.length">
            <referenceCard v-for="(reference, index) in myReferences" :refIndex="index+1" :isLastReference="myReferences.length-1 === index" :key="reference.id" :reference="reference" :userId="user && user.id" @set-likes="$emit('set-likes', $event)" :getWorkerNameById="getWorkerNameById" :onMyProfile="isMyProfile" @delete-reference="$emit('delete-reference', $event)"/>
        </div>
    </div>
</template>

<style lang="sass" scoped>
.myReferences
    margin-top: -20px
</style>

<script>
import referenceCard from '@/components/general/referenceCard.vue'
import noProjects from '@/components/general/noProjects.vue'

export default {
    props: ['user', 'competences', 'myReferences', 'refreshLoader', 'isMyProfile'],
    components: {referenceCard, noProjects},
    computed: {
        workers() {
            return this.$store.state.workers
        },
    },
    methods: {
        getWorkerNameById(id) {
            const worker = this.workers && this.workers[id]
            return worker && worker.firstname && worker.lastname && worker.firstname + ' ' + worker.lastname || 'Gelöschtes Profil'
        },
    }
}
</script>