<template>
  <ion-page>
    <div class="dashboard-wrapper" ref="dashboardWrapper">
    <!-- <ion-content :scroll-events="true" @ionScroll="loadMoreReferences($event)" ref="dashboardWrapper" class="dashboard-wrapper"> -->
      <!-- <ion-refresher slot="fixed" @ionRefresh="fetchData($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher> -->
      <transition name="slide-up">
        <loadingTop v-if="refreshLoader"/>
      </transition>
      <screenHeader headerTitle="Dashboard"/>
      <bonusBox v-if="!refreshLoader && !loading" :myBalance="myBalance"/>
      <noProjects v-if="!references.length && !loading"/>
      <div class="references" v-if="references.length && !refreshLoader">
        <referenceCard v-for="(reference, index) in references" :refIndex="index+1" :isLastReference="references.length-1 === index" :key="reference.id" :reference="reference" :userId="user && user.id" @set-likes="setLikes($event)" :getWorkerNameById="getWorkerNameById"/>
      </div>
      <ion-spinner v-if="initialLoading && loading && !references.length"></ion-spinner>
    <!-- </ion-content> -->
    </div>
  </ion-page>
</template>

<script>
import { referencesCollection, workersCollection } from '@/firebase'
import {
  IonPage,
  IonSpinner,
  // IonContent,
  // IonRefresher,
  // IonRefresherContent,
} from '@ionic/vue'
import bonusBox from '@/components/dashboard/bonusBox'
import screenHeader from '@/components/general/screenHeader'
import referenceCard from '@/components/general/referenceCard.vue'
import noProjects from '@/components/general/noProjects.vue'
import loadingTop from '@/components/dashboard/loadingTop.vue'
import fetchingAndGettingGlobalData from '@/mixins/fetchingAndGettingGlobalData'

export default {
  mixins: [fetchingAndGettingGlobalData],
  components: {
    IonPage,
    IonSpinner,
    // IonContent,
    // IonRefresher,
    // IonRefresherContent,
    loadingTop,
    bonusBox,
    referenceCard,
    screenHeader,
    noProjects,
  },
  data() {
    return {
      initialLoading: true,
      loading: true,
      refreshLoader: false,
      lastLoadedDocument: null,
      myBalance: 0,
    }
  },
  async mounted() {
    await this.fetchData()
    this.$refs.dashboardWrapper.addEventListener('scroll', async e => {
      // Refetch data when user scrolls to top
      if(e.target.scrollTop === 0) {
        this.refreshLoader = true
        await this.fetchData()
        this.refreshLoader = false
      }
      // Pagination
      if(((e.target.scrollTop + document.body.scrollTop) / (e.target.scrollHeight - e.target.clientHeight) * 100) > 95) {
        await this.getReferences()
      }
    })
  },
  watch: {
    async $route(r, oldR) {
      if(r.name === 'Dashboard' && oldR.name !== 'ReferenceComments' && oldR.name !== 'Profile') {
        await this.fetchData()
      }
    }
  },
  computed: {
    references() {
      return this.$store.state.dashboardReferences
    }
  },
  methods: {
    // async loadMoreReferences(ev) {
    //   let elem = this.$refs.dashboardWrapper

    //   if(!elem.getScrollElement) {
    //     elem = elem.$el
    //   }

    //   // the ion content has its own associated scrollElement
    //   const scrollElement = await elem.getScrollElement()

    //   const scrollPosition = ev.detail.scrollTop;
    //   const totalContentHeight = scrollElement.scrollHeight;
    //   const viewportHeight = elem.offsetHeight;

    //   const percentage = scrollPosition / (totalContentHeight - viewportHeight);

    //   // Pagination
    //   if((percentage * 100) > 95) {
    //     await this.getReferences()
    //   }
    // },
    async fetchData(/*ev*/) {

      // if(ev && ev.target && ev.target.complete) {
      //   this.refreshLoader = true
      // }
      this.refreshLoader = true

      // Reset values
      this.$store.commit('setDashboardReferences', [])
      this.lastLoadedDocument = null
      this.initialLoading = true
      this.loading = true

      // Get user data
      await this.getUser()
      
      // Get workers and competences to populate references
      await this.getWorkers()
      await this.getCompetences()
      await this.getMyBalance()
      await this.getReferences()

      setTimeout(() => {
        // if(ev && ev.target && ev.target.complete) {
          // this.refreshLoader = false
          // ev.target.complete()
        // }
        this.refreshLoader = false
      }, 500)
    },
      async getMyBalance() {
      const snapshot = await workersCollection.doc(this.user && this.user.id).collection('transactions').get()
      const myTransactions = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
      this.myBalance = myTransactions.reduce((a, b) => b.type === 'add' || !b.type ? a + parseInt(b.amount) : a - parseInt(b.amount), 0)
    },
    getWorkerNameById(id) {
      const worker = this.workers && this.workers[id]
      return worker && worker.firstname && worker.lastname && worker.firstname + ' ' + worker.lastname || 'Gelöschtes Profil'
    },
    setLikes({ likes, referenceId }) {
      const newReferences = this.references.map(r => r.id !== referenceId ? r : {...r, likes: likes})
      this.$store.commit('setDashboardReferences', newReferences)
    },
    async getReferences() {
      // If there's no more references or loading is already in progress and it's not initial loading
      if(this.lastLoadedDocument === 'ALL_REFERENCES_LOADED' || (this.loading && !this.initialLoading)) {
        return
      }

      this.initialLoading = false
      this.loading = true

      // Skip previously loaded references
      let snapshot

      if(this.lastLoadedDocument && !this.initialLoading) {
        snapshot = await referencesCollection.orderBy('createdOn', 'desc').startAfter(this.lastLoadedDocument).limit(5).get()
      } else {
        snapshot = await referencesCollection.orderBy('createdOn', 'desc').limit(5).get()
      }

      const references = snapshot.docs.map(doc => {
        const reference = { id: doc.id, ...doc.data() }
        reference.competences = reference.competences && reference.competences.length ? reference.competences.filter(c => this.competences[c]).map(c => this.competences[c]) : []
        reference.worker = this.workers && this.workers[reference.worker]
        return reference
      })

      this.$store.commit('setDashboardReferences', this.lastLoadedDocument ? [...this.references, ...references] : [...references])
      this.lastLoadedDocument = snapshot.docs.length ? snapshot.docs[snapshot.docs.length-1] : 'ALL_REFERENCES_LOADED'

      this.loading = false
    },
  },
}
</script>

<style lang="sass" scoped>
.dashboard-wrapper
  background: white
  overflow-y: scroll
  height: calc(100vh - 57px)

ion-refresher
  position: -webkit-fixed
  position: fixed
  top: 50px
  
.references
  padding: 0 5vw 50px

ion-spinner
  color: var(--indigo-dark)
  margin: 200px auto 0
  display: block
  transform: scale(0.85)
</style>