<template>
    <div class="activity-content">
        <span v-if="activity.type === 'login'">Du hast dich das erste Mal bei WeBuild angemeldet.</span>
        <span v-if="activity.type === 'referenceCommented'">Du hast auf dein Referenzprojekt <strong>{{activity.referenceName}}</strong> einen neuen Kommentar von <strong>{{activity.workerName}}</strong> erhalten.</span>
        <span v-if="activity.type === 'bonus'">Du hast für deine tolle Arbeit einen Bonus von <strong>{{activity.amount}} CHF</strong> erhalten. Gratulation! <span v-if="activity.comment">Kommentar: "{{activity.comment}}"</span></span>
        <span v-if="activity.type === 'withdrawal'">Dir wurden soeben <strong>{{activity.amount}} CHF</strong> auf dein Konto überwiesen. <span v-if="activity.comment">Kommentar: "{{activity.comment}}"</span></span>
        <span v-if="activity.type === 'review'" @click="$router.push({name: 'MyProfile', query: {tab: 'reviews'}})">Du hast von <strong>{{activity.reviewerFullname}}</strong> von der <strong>{{activity.reviewerCompany}}</strong> eine neue Bewertung für das Projekt <strong>{{activity.project}}</strong> erhalten.</span>
        <span v-if="activity.type === 'referenceAdded'">Du hast eine neues Referenzprojekt <strong @click="$router.push({name: 'MyProfile', query: {tab: 'references'}})">"{{activity.referenceName}}"</strong> erfasst.</span>
        <span v-if="activity.type === 'referenceLiked'"><strong>{{activity.workerName}}</strong> gefällt dein Referenzprojekt <strong @click="$router.push({name: 'MyProfile', query: {tab: 'references'}})">"{{activity.referenceName}}"</strong></span>
        <span v-if="activity.type === 'referenceLikedMultiple'"><strong>{{activity.numberOfLikes}} Personen</strong> gefällt dein Referenzprojekt <strong @click="$router.push({name: 'MyProfile', query: {tab: 'references'}})">"{{activity.referenceName}}"</strong>
        </span>
    </div>
</template>

<script>
export default {
    props: ['activity', 'user']
}
</script>

<style lang="sass" scoped>
.activity-content
    font-size: 12px
    font-weight: 400
    line-height: 20px
    color: var(--dim-gray-dark)
</style>