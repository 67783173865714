<template>
  <ion-page>
    <ion-content>
        <ion-spinner v-if="loadingScreen"></ion-spinner>
        <ion-icon :icon="chevronBackOutline" class="back" @click="step === 'initial' ? $router.back() : step = 'initial'" v-if="step !== 'sms-2'"/>
        <div class="request-review" v-if="step === 'initial' && !loadingScreen">
            <h3>Wie möchtest du die Bewertung einholen?</h3>
            <div class="option ion-activatable ripple-parent" @click="step = 'qr'">
                <img src="@/assets/qr-code.svg" alt="">
                <div class="text">
                    <span>QR Code scannen</span>
                    <small>Lasse die Person einen QR Code einscannen.</small>
                </div>
                <ion-icon :icon="chevronForwardOutline"/>
                <ion-ripple-effect/>
            </div>
            <div class="option ion-activatable ripple-parent" @click="step = 'sms'">
                <img src="@/assets/text-msg.svg" alt="">
                <div class="text">
                    <span>Bewertungslink senden</span>
                    <small>Sende der Person einen Bewertungslink via SMS.</small>
                </div>
                <ion-icon :icon="chevronForwardOutline"/>
                <ion-ripple-effect/>
            </div>
        </div>
        <div class="request-review qr" v-if="step === 'qr' && !loadingScreen">
          <canvas ref="canvas"></canvas>
          <h3>QR Code scannen</h3>
          <p>Lasse den QR Code von der Person scannen, von welcher du eine Bewertung einholen möchtest. Diese kann die Bewertung dann erfassen.</p>
          <ion-button @click="$router.back()">Fertig</ion-button>
        </div>
        <div class="request-review sms" v-else-if="step === 'sms'">
          <img src="@/assets/text-msg.svg" alt="">
          <h3>Bewertungslink senden</h3>
          <p>Sende der Person einen Bewertungslink via SMS. Wenn sie diesen aufruft, kann sie dir eine Bewertung abgeben.</p>
          <div class="form-field">
            <label>Name</label>
            <input type="text" placeholder="Name der Kontaktperson" v-model="name">
          </div>
          <div class="form-field">
            <label>TELEFONNUMMER</label>
            <div class="phoneInput">
              <select v-model="countryCode">
                <option value="+41">+41</option>
                <option value="+46">+46</option>
              </select>
              <input v-model="phoneNumber" placeholder="Telefonnummer (Mobile)" min="0" inputmode="numeric" pattern="[0-9]*">
            </div>
          </div>
          <div class="form-field">
            <label>PROJEKT</label>
            <input type="text" placeholder="Name des Projekts" v-model="project">
          </div>
          <ion-button @click="sendSms">Senden</ion-button>
        </div>
        <div class="request-review sms-2" v-if="step === 'sms-2' && !loadingScreen">
          <img src="@/assets/phone-checkmark.svg" alt="">
          <h3>Bewertungslink versendet</h3>
          <p>Wir haben <strong>{{name}}</strong> den Bewertungslink an die Nummer <strong>{{phoneNumberWithCountryCode}}</strong> versendet. Warte nun bis sie/er die Bewertung abgegeben hat.</p>
          <ion-button @click="$router.back()">Fertig</ion-button>
        </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonIcon, IonRippleEffect,
IonSpinner, IonButton } from '@ionic/vue'
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons'
import { defineComponent } from 'vue'
import pn from 'awesome-phonenumber'
import QRCode from 'qrcode'
import toast from '@/mixins/toast'
import loader from '@/mixins/loader'
import sendReviewRequestSMS from '@/services/sendReviewRequestSMS'
import generateUUID from '@/utils/generateUUID'
import generateMessageFromTemplate from '@/utils/generateMessageFromTemplate'
import { settingsCollection, reviewRequestCollection } from '@/firebase'

export default defineComponent({
  mixins: [toast, loader],
  components: {
    IonContent,
    IonPage,
    IonIcon,
    IonRippleEffect,
    IonSpinner, 
    IonButton
  },
  data() {
    return {
      step: 'initial',
      phoneNumber: '',
      countryCode: '+41',
      name: '',
      project: '',
      verificationId: null,
      loadingScreen: true,
    }
  },
  watch: {
    step(s) {
      let segment = {}
       if(this.user && this.user.segment) {
         try {
            segment = JSON.parse(this.user.segment)
         } catch (e) {
            console.log(e)
         }
      }

      if(s === 'qr') {
        setTimeout(() => this.generateQR())
        window.analytics.track('Review Request Step 2', {
          name: this.user && this.user.firstname + ' ' + this.user.lastname,
          jobtitle: this.user && this.user.jobtitle,
          requestId: this.verificationId,
          ...segment,
          type: 'QR'
        })
      }

      if(s === 'sms') {
        window.analytics.track('Review Request Step 2', {
          name: this.user && this.user.firstname + ' ' + this.user.lastname,
          jobtitle: this.user && this.user.jobtitle,
          requestId: this.verificationId,
          ...segment,
          type: 'Message'
        })
      }

      if(s === 'sms' || s === 'qr' || s === 'sms-2') {
        this.$store.commit('setHideTabs', true)
      } else {
        this.$store.commit('setHideTabs', false)
      }
    },
    $route(r) {
      if(r.name !== 'RequestReview') {
        setTimeout(() => this.resetForm(), 500)
        this.$store.commit('setHideTabs', false)
      } else {
        this.createVerificationCode()
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    phoneNumberWithCountryCode() {
      return this.countryCode.toString()+(this.phoneNumber.toString().replace(/\s/g,''))
    },
  },
  mounted() {
    this.createVerificationCode()
  },
  methods: {
    async createVerificationCode() {
      this.verificationId = generateUUID()

      // Add review request to database
      await reviewRequestCollection.doc(this.verificationId).set({
        workerId: this.user && this.user.id,
        isCompleted: false,
        created: new Date()
      })

      this.loadingScreen = false
    },
    async generateQR() {
      const appURL = await this.getAppRootURL()
      QRCode.toCanvas(this.$refs.canvas, `${appURL}/review/${this.verificationId}?type=qr`, { version: 5, color: { dark: "#263142" }})
    },
    async getAppRootURL() {
      const app = await settingsCollection.doc('app').get()
      const appData = app.data()
      return appData.url
    },
    resetForm() {
      this.step = 'initial'
      this.phoneNumber = ''
      this.name = ''
      this.loadingScreen = true
    },
    async sendSms() {

      if(!this.name.trim()) {
        this.openToast('Gib den Namen der Person an, welche die Bewertung abgeben soll.', 6000)
        return
      }

      if(!pn(this.phoneNumberWithCountryCode.toString()).isValid()) {
        this.openToast('Diese Telefonnummer hat ein ungültiges Format.', 6000)
        return
      }

      if(this.phoneNumberWithCountryCode === this.user.phone) {
        this.openToast('Du kannst nicht deine eigene Telefonnummer eingeben.', 6000)
        return
      }

      this.startLoader()
      
      // Generate message template and send it to phone of reviewer
      const appURL = await this.getAppRootURL()
      const msg = await generateMessageFromTemplate([this.name, this.user && this.user.firstname, this.user && this.user.lastname, `${appURL}/review/${this.verificationId}?phone=${encodeURIComponent(this.phoneNumberWithCountryCode)}&name=${encodeURIComponent(this.name)}${this.project ? '&project='+encodeURIComponent(this.project) : ''}&type=message`], 'reviewRequest')
      const res = await sendReviewRequestSMS(this.phoneNumberWithCountryCode, msg, this.user.token)

      if(res.status !== 200) {
        this.openToast('Es ist ein Fehler aufgetreten. Bitte wenden Sie sich an unseren Kundendienst.', 6000)
      } else {

        let segment = {}
         if(this.user && this.user.segment) {
           try {
              segment = JSON.parse(this.user.segment)
           } catch (e) {
              console.log(e)
           }
        }

        window.analytics.track('Review Request Sent', {
          name: this.user && this.user.firstname + ' ' + this.user.lastname,
          requestId: this.verificationId,
          jobtitle: this.user && this.user.jobtitle,
          project: this.project,
          ...segment
        })

        this.step = 'sms-2'
      }
      this.stopLoader()
    }
  },
  setup() {
    return {chevronBackOutline, chevronForwardOutline}
  }
})
</script>

<style lang="sass" scoped>
.back
    margin-left: 30px
    margin-top: 30px
    font-size: 25px
    cursor: pointer

.form-field
  display: flex
  flex-direction: column
  padding: 0 31px 9px
  label
    text-transform: uppercase
    font-size: 12px
    line-height: 14px
    font-weight: 700
    margin-bottom: 4px
    color: var(--dim-gray-light)
  input
    resize: none
    outline: 0
    border: 0
    border-bottom: 1px solid #E9EAEC
    font-size: 15px
    padding: 10px 0
    color: var(--dim-gray-dark)
    &::placeholder
      color: var(--dim-gray-dark)

.request-review
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  margin: 5px 0 50px
  h3
    text-align: center
    color: var(--dim-gray-dark)
    font-size: 22px
    font-weight: 700
    margin: 20px 40px
  .option
    max-width: 400px
    cursor: pointer
    position: relative
    margin: 10px 40px
    padding: 26px 20px
    display: flex
    justify-content: space-between
    align-items: center
    border: 1px solid var(--silver-light)
    border-radius: 5px
    img
      width: 65px
      height: auto
    .text
      margin-left: 10px
      display: block
      span
        color: var(--indigo-medium)
        font-size: 18px
        font-weight: 700
        margin-bottom: 8px
        display: block
        line-height: 21px
      small
        color: var(--dim-gray-light)
        font-size: 14px
        font-weight: 500
        line-height: 20px
        display: block
    ion-icon
      margin-left: 12px
      font-size: 24px
      width: 50px
      color: var(--silver-dark)

.sms
  img
    width: 65px
    height: auto
  h3, p
    text-align: center
  h3
    margin-bottom: 0
    font-size: 22px
    line-height: 24px
    font-weight: 700
    color: var(--dim-gray-dark)
  p
    margin: 15px 30px 40px
    font-size: 14px
    font-weight: 400
    line-height: 20px
  input, ion-button
    width: 300px
    margin-bottom: 10px
    flex-grow: 0
    box-sizing: border-box
  input
    margin-bottom: 17px
    padding: 10px 0
    border: 0
    border-bottom: 1px solid var(--silver-light)
    outline: 0
    -webkit-appearance: none
    -moz-appearance: textfield
    font-size: 15px
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button
      -webkit-appearance: none
      margin: 0
  .phoneInput
    width: 300px
    display: flex
    margin-bottom: 17px
    select
      background: transparent
      padding: 0 5px
      outline: 0
      border: 1px solid white
      border-bottom: 1px solid #E9EAEC
      color: var(--dim-gray-dark)
    input
      width: 100%
      padding-left: 7px
      margin: 0

.sms-2
  height: calc(100vh - 60px)
  margin: 0
  img
    width: 65px
    height: auto
  h3, p
    text-align: center
  h3
    margin-bottom: 0
    font-size: 22px
    line-height: 24px
    font-weight: 700
    color: var(--dim-gray-dark)
  p
    margin: 15px 30px 25px
    font-size: 14px
    font-weight: 400
    line-height: 20px
    color: var(--dim-gray-dark)

.qr
  margin: 40px 0 0
  h3, p
    text-align: center
  h3
    margin-bottom: 0
    font-size: 22px
    line-height: 24px
    font-weight: 700
    color: var(--dim-gray-dark)
  p
    color: var(--dim-gray-dark)
    margin: 15px 30px 25px
    font-size: 14px
    font-weight: 400
    line-height: 20px
  
ion-spinner
  color: var(--indigo-dark)
  margin: auto
  display: block
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  transform: scale(0.85)
</style>