<template>
    <textarea
        ref="txtarea"
        rows="2"
        :placeholder="placeholder"
        :value="value"
        @input="$emit('update:value', $event.target.value)"
        @keydown="expandTextArea"
        :style="{borderBottom: emptyRequiredField ? '1px solid red' : ''}">
    </textarea>
</template>

<script>
export default {
    inheritAttrs: false,
    props: ['placeholder', 'value', 'emptyRequiredField'],
    methods: {
        expandTextArea() {
            const el = this.$refs.txtarea
            setTimeout(() => {
                el.style.cssText = 'height: auto;';
                el.style.cssText = 'height:' + el.scrollHeight + 'px';
            }, 0)
        }
    }
}
</script>

<style lang="sass" scoped>
textarea
  overflow: hidden
  min-height: 38px
  resize: none
  outline: 0
</style>