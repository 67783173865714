<template>
  <ion-page>
    <ion-content>
      <div class="login">
        <ion-icon :icon="chevronBackOutline" class="back" @click="step = 0" v-if="step"/>
        <img src="@/assets/logo.svg" class="logo">
        <h5>{{!step ? 'Melde dich bei WeBuild an' : 'Gib den 6-stelligen verifizierungs Code ein'}}</h5>
        <small v-if="step">Der Code wurde an {{phone}} gesendet. <br>Bis zum Erhalt kann es einige Sekunden dauern.<br><span @click="resendVerificationCode" style="margin-top: 5px; display: block">Erneut senden</span></small>
        <sendSMS v-show="step === 0" :step="step" @setStep="step = $event" @set-phone="phone = $event"/>
        <enterVerificationCode v-show="step === 1" :step="step" @setStep="step = $event" :phone="phone"/>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonIcon } from '@ionic/vue'
import { chevronBackOutline } from 'ionicons/icons'
import { defineComponent } from 'vue'
import sendSMS from "@/components/login/sendSMS.vue"
import enterVerificationCode from "@/components/login/enterVerificationCode.vue"
import { firebase } from '@/firebase'
import toast from '@/mixins/toast'
import loader from '@/mixins/loader'

export default defineComponent({
  mixins: [toast, loader],
  components: {
    sendSMS,
    enterVerificationCode,
    IonContent,
    IonPage,
    IonIcon,
  },
  data() {
    return {
      step: 0,
      phone: null,
    }
  },
  methods: {
    resendVerificationCode() {
      this.startLoader()
      firebase.auth().signInWithPhoneNumber(this.phone, window.recaptchaVerifier)
      .then(confirmationResult => {
        this.stopLoader()
        window.confirmationResult = confirmationResult
      }).catch(error => {
        this.stopLoader()
        this.openToast(error.message, 7000)
      })
    },
  },
  setup() {
    return {chevronBackOutline}
  }
})
</script>

<style lang="sass">
.login 
  display: flex
  flex-direction: column
  align-items: center
  width: 100vw
  height: 85vh
  .back
    cursor: pointer
    position: absolute
    left: 30px
    top: 30px
    font-size: 25px
  .logo
    margin: 50px auto 20px
    display: block
    width: 50px
  h5
    margin: 0 50px 15px
    text-align: center
    font-size: 19px
    font-weight: 500
  small
    text-align: center
    margin-bottom: 50px
    span
      text-decoration: underline
      cursor: pointer

.grecaptcha-badge
  visibility: hidden
</style>