<template>
    <div class="reference-card" :class="{isCommentsView}">
        <div class="card-above">
            <ion-icon class="reference-comments-back" v-if="isCommentsView" :icon="chevronBackOutline" @click="$router.back()"/>
            <div @click="reference.worker && reference.worker.id && (user.id !== reference.worker.id ? $router.push({name: 'Profile', params: {workerId: reference.worker.id}}) : $router.push({name: 'MyProfile'}))" class="img-wrapper" slot="start" :style="{background: 'url('+workerPhoto+')', backgroundSize: 'cover', backgroundPosition: 'center'}">
            </div>
            <ion-label>
                <h3 @click="reference.worker && reference.worker.id && (user.id !== reference.worker.id ? $router.push({name: 'Profile', params: {workerId: reference.worker.id}}) : $router.push({name: 'MyProfile'}))">{{workerName}}</h3>
                <p>{{referenceDate}}</p>
            </ion-label>
            <div class="edit" v-if="onMyProfile" v-click-away="closeEditDropdown">
                <ion-icon :icon="ellipsisHorizontal" @click="showEditDropdown = !showEditDropdown"/>
                <transition name="fade">
                    <div class="edit-dropdown" v-if="showEditDropdown">
                        <div class="arrow"></div>
                        <div class="edit-dropdown-item ion-activatable ripple-parent" @click="$router.push({name: 'EditReference', params: { id: reference.id }})">
                            <ion-icon :icon="createOutline"/>
                            <span>Bearbeiten</span>
                            <ion-ripple-effect/>
                        </div>
                        <div class="edit-dropdown-item ion-activatable ripple-parent" @click="triggerDeleteModal">
                            <ion-icon :icon="trashOutline"/>
                            <span>Löschen</span>
                            <ion-ripple-effect/>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <ion-card :style="{position: 'relative', zIndex: refIndex ? 100000 - refIndex : 2}">
            <div class="like-slide-wrapper">
                <transition name="slide-down">
                    <div v-show="showLikeAnimation" class="like-reference-card-dblclick">
                        <img src="@/assets/like.svg">
                    </div>
                </transition>
                <ion-slides
                    v-if="reference.photos && reference.photos.length"
                    :pager="reference.photos && reference.photos.length > 1"
                    :options="{
                    initialSlide: 0,
                    speed: 400,
                    }"
                >
                    <ion-slide v-for="(refPhoto, index) in reference.photos" :key="refPhoto" :style="{background: 'url('+refPhoto+')', backgroundSize: 'cover', backgroundPosition: 'center'}" @click="handleClick(index, reference.photos)">
                    </ion-slide>
                </ion-slides>
            </div>
            <ion-card-header>
                <ion-card-title>{{reference.project}}</ion-card-title>
            </ion-card-header>

            <ion-card-content>
                <p>{{reference.description}}</p>
                <div v-if="competences && competences.length" class="competences">
                    <ion-chip disabled="true" v-for="competence in competences" :key="reference._id+competence">
                        <ion-label color="primary">{{competence}}</ion-label>
                    </ion-chip>
                </div>
                <div class="likes-and-comments" :class="{'no-likes-or-comments': !reference.commentCount && (!reference.likes || (reference.likes && !reference.likes.length))}">
                  <div class="people-who-liked" v-if="reference.likes && reference.likes.length" v-click-away="closeDropdown">
                    <span @click="showDropdown = !showDropdown">Gefällt {{reference.likes && reference.likes.length}} mal</span>
                    <transition name="fade">
                        <div class="people-who-liked-dropdown" :class="{isLastReference}" v-if="showDropdown">
                            <div class="arrow"></div>
                            <span v-for="like in reference.likes" :key="like">{{getWorkerNameById(like)}}</span>
                            <span v-if="reference.likes.length > 10">+ {{reference.likes.length - 10}} {{(reference.likes.length - 10) === 1 ? 'Person' : 'Personen'}}</span>
                        </div>
                    </transition>
                  </div>
                  <span @click="$route.name !== 'ReferenceComments' ? $router.push({name: 'ReferenceComments', params: {referenceId: reference.id}, query: {scrollToComments: true}}) : $emit('scroll-to-comments')" class="comments" v-if="reference.commentCount">{{reference.commentCount}} Kommentar{{reference.commentCount > 1 ? 'e' : ''}}</span>
                </div>
            </ion-card-content>
        </ion-card>
        <div class="card-below" :class="{'has-comments': reference.commentCount > 0}">
            <ion-chip @click="like" :class="{isLiked}">
                <img :src="isLiked ? require('@/assets/like.svg') : require('@/assets/like-gray.svg')">
                <span>Gefällt mir</span>
            </ion-chip>
            <ion-chip @click="$route.name !== 'ReferenceComments' ? $router.push({name: 'ReferenceComments', params: {referenceId: reference.id}}) : $emit('focus-add-comment-input')">
                <ion-icon :icon="chatboxOutline" />
                <span>Kommentieren</span>
            </ion-chip>
        </div>
    </div>
</template>

<style lang="sass">
.like-slide-wrapper
    position: relative
    .like-reference-card-dblclick
        position: absolute
        z-index: 10000000000
        background: white
        border-radius: 50%
        width: 52px
        height: 52px
        left: 0
        top: 0
        bottom: 10px
        right: 0
        margin: auto
        img
            position: absolute
            left: 0
            right: 0
            bottom: 0
            top: 0
            margin: auto
            width: 30px
            height: 30px

.reference-card
    margin-top: 25px
    .card-above
        display: flex
        align-items: center
        margin-bottom: 9px
        .reference-comments-back
            font-size: 20px
            cursor: pointer
            margin-right: 12px
        .img-wrapper
            cursor: pointer
            overflow: hidden
            border-radius: 50%
            margin-right: 10px
            width: 40px
            height: 40px
            img
                object-fit: cover
                min-width: 100%
                min-height: 100%
        ion-label
            h3
                cursor: pointer
                color: var(--indigo-medium)
                font-size: 14px
                margin-bottom: 1px
                font-weight: 700
                line-height: 16px
            p
                color: var(--dim-gray-light)
                font-size: 13px
                line-height: 16px
                margin: 0
                text-transform: capitalize
        .edit
            margin-left: auto
            margin-right: 7px
            cursor: pointer
            position: relative
            ion-icon
                color: var(--silver-dark)
                font-size: 20px
            .edit-dropdown
                position: absolute
                z-index: 1000000
                top: 30px
                right: -15px
                background: var(--dim-gray-dark)
                padding: 5px 0
                box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px
                border-radius: 10px
                cursor: pointer
                .arrow
                    width: 15px
                    height: 15px
                    background: var(--dim-gray-dark)
                    transform: rotateZ(45deg)
                    position: absolute
                    z-index: 1
                    right: 18px
                    top: -7px
                .edit-dropdown-item
                    position: relative
                    z-index: 2
                    display: flex
                    padding: 10px 15px
                    ion-icon
                        color: white
                    span
                        margin-left: 10px
                        display: block
                        font-size: 16px
                        font-weight: 500
                        text-transform: none
                        color: white
    ion-card
        overflow: visible
        margin: 5px 0 6px
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px
        ion-slides
            cursor: pointer
            border-top-left-radius: 4px
            border-top-right-radius: 4px
            height: 250px
            --bullet-background-active: #fff
            --bullet-background: #fff
        ion-card-header
            padding-left: 0
            padding-right: 0
            padding-bottom: 6px
            ion-card-title
                --color: var(--dim-gray-dark)
                font-weight: 700
                font-size: 14px
                line-height: 1.25rem
                letter-spacing: normal !important
                margin-bottom: 0
                margin-left: 16px
                margin-right: 16px
                white-space: pre-line
        ion-card-content
            padding: 0
            padding-bottom: 5px
            p
                white-space: pre-line
                color: var(--dim-gray-dark)
                font-weight: normal
                font-size: 13px
                line-height: 1.25rem
                margin-left: 16px
                margin-right: 16px
                margin-bottom: 0
            .competences
                margin-left: 16px
                margin-right: 16px
                margin-top: 15px
                margin-bottom: -4px
            .likes-and-comments
                padding-left: 16px
                padding-right: 16px
                margin-top: 11px
                border-top: 1px solid var(--silver-light)
                padding-top: 7px
                display: flex
                align-items: center
                justify-content: space-between
                .comments
                    cursor: pointer
                    color: var(--dim-gray-light)
                    font-size: 12px
                    font-weight: 500
                    line-height: 16px
                    letter-spacing: -.5px
                    margin-left: auto
                    margin-top: 2.5px
                    margin-bottom: 5.5px
                .people-who-liked
                    position: relative
                    margin-bottom: 2.5px
                    span
                        color: var(--dim-gray-light)
                        font-size: 12px
                        font-weight: 500
                        line-height: 16px
                        margin-right: 16px
                        letter-spacing: -.5px
                        cursor: pointer
                    .people-who-liked-dropdown
                        position: absolute
                        z-index: 100
                        left: -10px
                        top: 27px
                        background: var(--dim-gray-dark)
                        opacity: .95
                        padding: 8px 12px
                        box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px
                        border-radius: 10px
                        cursor: pointer
                        .arrow
                            width: 10px
                            height: 10px
                            background: var(--dim-gray-dark)
                            transform: rotateZ(45deg)
                            opacity: .95
                            position: absolute
                            z-index: 1
                            left: 10px
                            top: -5px
                        span
                            white-space: pre
                            position: relative
                            z-index: 2
                            padding: 2px 0
                            display: block
                            font-size: 12px
                            font-weight: 500
                            text-transform: none
                            color: white
                    .isLastReference
                        top: auto
                        bottom: 27px
                        .arrow
                            top: auto
                            bottom: -5px
            .no-likes-or-comments
                border-top: none
                margin: 0
            ion-chip
                --background: var(--indigo-lighter)
                --color: var(--indigo-dark)
                font-size: 13px
                line-height: 20px
                font-weight: 400
                padding: 12px 20px
                height: 15px
                margin: 0 8px 8px 0
                opacity: 1
    .card-below
        display: flex
        align-items: center
        margin-top: -2px
        ion-chip
            --background: none
            position: relative
            right: 14px
            cursor: pointer
            ion-icon
                font-size: 19px
                color: var(--dim-gray-light)
                margin-right: 6px
            img
                width: 19px
                transform: scale(0.9)
                margin-right: 5px
                margin-bottom: 2px
            span
                color: var(--dim-gray-light)
                font-size: 12px
                font-weight: 700
                line-height: 16px
                letter-spacing: -.5px
        .isLiked
            span
                color: var(--indigo-medium)

.reference-card.isCommentsView
    margin-top: 0
    .card-above
        width: 100vw
        top: 0
        left: 0
        position: fixed
        z-index: 400
        background: white
        padding: 17px 5vw 15px
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px
        margin-bottom: 25px
    ion-card
        margin-top: 90px
    ion-card, .card-below
        margin-left: 5vw
        margin-right: 5vw
    .has-comments
        border-bottom: 1px solid var(--silver-light)

.delete-reference-alert 
    .alert-head
        display: none
    .alert-wrapper
        padding-top: 20px
</style>

<script>
import { referencesCollection, workersCollection } from '@/firebase'
import { ellipsisHorizontal, createOutline, trashOutline, chevronBackOutline, chatboxOutline } from 'ionicons/icons'
import {   
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonSlides,
  IonSlide,
  IonChip,
  IonLabel,
  IonIcon,
  IonRippleEffect,
  alertController
} from '@ionic/vue'
import activities from '@/mixins/activities'
import moment from "moment"
moment.locale('de-ch')

export default {
    mixins: [activities],
    props: ['refIndex', 'reference', 'userId', 'getWorkerNameById', 'isLastReference', 'onMyProfile', 'isCommentsView'],
    components: {
        IonCard,
        IonCardHeader,
        IonCardContent,
        IonCardTitle,
        IonSlides,
        IonSlide,
        IonChip,
        IonLabel,
        IonIcon,
        IonRippleEffect,
    },
    setup() {
        return { ellipsisHorizontal, createOutline, trashOutline, chevronBackOutline, chatboxOutline }
    },
    data() {
        return {
            showLikeAnimation: false,
            skipFullscreen: false,
            clickCounter: 0,
            showDropdown: false,
            showEditDropdown: false,
            showFullscreen: false,
            fullscreenIndex: 0
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        },
        competences() {
            return this.reference && this.reference.competences && this.reference.competences.map(c => c.name)
        },
        workerName() {
            return this.reference.worker && this.reference.worker.firstname && this.reference.worker.lastname && this.reference.worker.firstname + ' ' + this.reference.worker.lastname
        },
        workerPhoto() {
            return this.reference.worker && this.reference.worker.photo || require("@/assets/reference-avatar.svg")
        },
        isLiked() {
            return this.reference.likes && this.reference.likes.includes(this.userId)
        },
        referenceDate() {
            const referenceDate = this.reference.createdOn.seconds * 1000
            const currentDate = new Date().getTime()
            const timePassed = currentDate - referenceDate
            const secs = timePassed / 1000

            let formattedDate
            if(secs < 60) {
                formattedDate = 'Vor 1 Minute'
            } else if(secs >= 60 && secs < 3600) {
                formattedDate = `Vor ${Math.round(secs/60)} Minuten`
            } else if(secs >= 3600 && secs < 3600*21) {
                formattedDate = moment(referenceDate).startOf('hour').fromNow()
            } else if(secs >= 3600*21 && secs < 3600*24*2) {
                formattedDate = 'Vor 1 Tag'
            } else if(secs >= 3600*24*2 && secs < 3600*24*7) {
                formattedDate = `Vor ${Math.round(secs/60/60/24)} Tagen`
            } else if(secs >= 3600*24*7 && secs < 3600*24*8) {
                formattedDate = 'Vor 1 Woche'
            } else {
                formattedDate = moment(referenceDate).format('Do MMMM YYYY, HH:MM')
            }
            return formattedDate
        }
    },
    methods: {
        handleClick(index, photos) {
            this.clickCounter++
            if(this.clickCounter === 2) {
                this.skipFullscreen = true
                this.like()
                this.clickCounter = 0
                return
            }

            setTimeout(() => {
                if(this.skipFullscreen) {
                    this.skipFullscreen = false
                } else {
                    this.clickCounter = 0
                    this.$store.commit('setFullscreen', {index, photos})
                }
            }, 250)
        },
        async triggerDeleteModal() {
            const alert = await alertController
                .create({
                cssClass: 'delete-reference-alert',
                message: 'Möchtest du dieses Referenzprojekt wirklich löschen? Die Aktion kann nicht rückgängig gemacht werden.',
                buttons: [
                    {
                        text: 'Abbrechen',
                        role: 'cancel',
                    },
                    {
                        text: 'Ja',
                        handler: () => {
                            this.$emit('delete-reference', {referenceId: this.reference.id, referencePhotos: this.reference.photos})
                        },
                    },
                ],
                });
            return alert.present();
        },
        closeDropdown() {
            this.showDropdown = false
        },
        closeEditDropdown() {
            this.showEditDropdown = false
        },
        async addLikeActivity(reference) {
            if(!reference.likes.includes(this.userId)) {
                let workerAlreadyLikedReference = await workersCollection.doc(reference.worker).collection('activities').where('workerId', '==', this.userId).where('referenceId', '==', reference.id).get()
                workerAlreadyLikedReference = workerAlreadyLikedReference.docs.length
                if(!workerAlreadyLikedReference) {
                    this.addActivity({
                        type: 'referenceLiked',
                        createdOn: new Date(),
                        referenceId: reference.id,
                        referenceName: reference.project,
                        workerId: this.userId,
                        workerName: this.userId === reference.worker ? 'Dir' : this.user && this.user.firstname + ' ' + this.user.lastname
                    }, reference.worker)
                }
            }
        },
        async like() {

            const refDoc = await referencesCollection.doc(this.reference.id).get()
            const reference = { id: refDoc.id, ...refDoc.data() }
            
            reference.likes = reference.likes || []
            
            this.addLikeActivity(reference)

            const likes = reference.likes.includes(this.userId) ? reference.likes.filter(l => l !== this.userId) : [...reference.likes, this.userId]

            if(!reference.likes.includes(this.userId)) {

                this.showLikeAnimation = true
                setTimeout(() => {
                    this.showLikeAnimation = false
                }, 500)

                let segment = {}
                if(this.user && this.user.segment) {
                    try {
                        segment = JSON.parse(this.user.segment)
                    } catch (e) {
                        console.log(e)
                    }
                }
                window.analytics.track('Reference Liked', {
                    name: this.user && this.user.firstname + ' ' + this.user.lastname,
                    jobtitle: this.user && this.user.jobtitle,
                    ...segment
                })
            }

            this.$emit('set-likes', {likes, referenceId: reference.id})

            await referencesCollection.doc(this.reference.id).update({likes})
        }
    }
}
</script>