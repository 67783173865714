<template>
  <ion-page>
    <div ref="activitiesWrapper" class="activities-wrapper">
      <screenHeader headerTitle="AKTIVITÄTEN"/>
      <div class="activities-groups">
          <div class="activity-group" v-for="(activities, index) in activitiesGrouppedByDates" :key="index">
            <span class="activity-date">{{index}}</span>
            <div class="activity" v-for="activity in activities" :key="activity.id">
              <img :src="renderActivityIcon(activity.type)"/>
              <activityContent :activity="activity" :user="user"/>
            </div>
            <div class="separator"></div>
          </div>
      </div>
    </div>
  </ion-page>
</template>

<script>
import { IonPage } from '@ionic/vue'
import screenHeader from '@/components/general/screenHeader.vue'
import activityContent from '@/components/activities/activityContent.vue'
import activities from '@/mixins/activities'
import { workersCollection } from '@/firebase'
import moment from "moment"
moment.locale('de-ch')

export default {
    components: {IonPage, screenHeader, activityContent},
    mixins: [activities],
    async mounted() {
        await this.updateViewedActiviesOn()

        setTimeout(() => {
          this.$refs.activitiesWrapper.addEventListener('scroll', async e => {
            // Pagination
            if(((e.target.scrollTop + document.body.scrollTop) / (e.target.scrollHeight - e.target.clientHeight) * 100) > 95) {
              await this.getActivities()
            }
          })
        }, 500)
    },
    watch: {
        async $route(r) {
            if(r.name === 'Activities') {
                await this.updateViewedActiviesOn()
            }
        }
    },
    computed: {
      lastLoadedDocument() {
        return this.$store.state.lastLoadedActivity
      },
      activitiesGrouppedByDates() {
        const grouppedActivities = {}
        this.activities.forEach(a => {
          let activityDate = moment(a.createdOn.seconds*1000).format('DD. MMMM YYYY')
          if(grouppedActivities[activityDate]) {
            const likedIndex = grouppedActivities[activityDate].findIndex(act => act.referenceId === a.referenceId)
            if(a.type === 'referenceLiked' && likedIndex !== -1) {
              if(grouppedActivities[activityDate][likedIndex].type === 'referenceLikedMultiple') {
                grouppedActivities[activityDate][likedIndex].numberOfLikes++
              } else {
                grouppedActivities[activityDate][likedIndex] = {...grouppedActivities[activityDate][likedIndex], numberOfLikes: 2, type: 'referenceLikedMultiple'}
              }
            } else {
              grouppedActivities[activityDate].push(a)
            }
          } else {
            grouppedActivities[activityDate] = [a]
          }
        })
        return grouppedActivities
      }
    },
    data() {
      return {
        loadingActivities: false,
      }
    },
    methods: {
      async getActivities() {
        // If there's no more activities or loading is already in progress
        if(this.lastLoadedDocument === 'ALL_ACTIVITIES_LOADED' || this.loadingActivities) {
          return
        }

        this.loadingActivities = true
        const snapshot = await workersCollection.doc(this.user && this.user.id).collection('activities').orderBy('createdOn', 'desc').startAfter(this.lastLoadedDocument).limit(10).get()
        
        const activities = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}))

        this.$store.commit('setActivities', [...this.activities, ...activities])
        this.$store.commit('setLastLoadedActivity', snapshot.docs.length ? snapshot.docs[snapshot.docs.length-1] : 'ALL_ACTIVITIES_LOADED')

        this.loadingActivities = false
      },
      renderActivityIcon(activityType) {
        if(activityType === 'login') {
          return require('@/assets/icon-login.svg')
        } else if(activityType === 'bonus') {
          return require('@/assets/icon-bonus.svg')
        } else if(activityType === 'withdrawal') {
          return require('@/assets/icon-withdrawal.svg')
        } else if(activityType === 'review') {
          return require('@/assets/icon-review.svg')
        } else if(activityType === 'referenceLiked' || activityType === 'referenceLikedMultiple') {
          return require('@/assets/icon-like.svg')
        } else if(activityType === 'referenceAdded') {
          return require('@/assets/icon-referenceproject.svg')
        } else if(activityType === 'referenceCommented') {
          return require('@/assets/icon-referenceCommented.svg')
        } 
      }
    }
}
</script>

<style lang="sass" scoped>
.activities-wrapper
  background: white
  overflow-y: scroll
  height: calc(100vh - 57px)
  .activities-groups
    margin: 20px 5vw
    .activity-group
      margin-bottom: 30px
      .activity-date
        font-size: 12px
        font-weight: 700
        line-height: 16px
        color: var(--dim-gray-dark)
        margin-bottom: 12px
        display: block
      .activity
        display: flex
        margin-bottom: 10px
        img
          width: 18px
          height: auto
          margin-top: 2px
          margin-right: 12px
      .separator
        width: 36px
        height: 1px
        background: var(--dim-gray-light-2)
        margin: 25px auto 0
</style>