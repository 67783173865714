<template>
  <ion-app>
    <fullscreenSlider />
    <ion-router-outlet v-if="siteLoaded" />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue'
import routeGuardingAndUpdateUserActivity from '@/mixins/routeGuardingAndUpdateUserActivity'
import fullscreenSlider from '@/components/general/fullscreenSlider'

export default {
  name: 'App',
  mixins: [routeGuardingAndUpdateUserActivity],
  components: {
    IonApp,
    IonRouterOutlet,
    fullscreenSlider,
  },
}
</script>

<style lang="sass">
@import '@/assets/index.sass'

</style>