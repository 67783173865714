import { settingsCollection } from '@/firebase'

export default async (data, templateId) => {
    // data -> array | templateId -> string

    // Get notifications collection
    const notifications = await settingsCollection.doc('notifications').get()
    const notificationsData = notifications.data()

    // Template data
    const template = notificationsData.templates.find(t => t.id === templateId)

    // Map field values from data argument to field names in template and get template message
    const templateMappedValues = template.fieldnames.replace(/ +?/g, '').split(',').map((f, index) => ({name: f, value: data[index]}))
    let templateMessage = template.message

    // Replace template variables with values
    templateMappedValues.forEach(field => {
        templateMessage = templateMessage.replace(new RegExp(field.name, 'g'), field.value);
    })
    
    return templateMessage
}