<template>
  <ion-page>
    <ion-content>
        <ion-spinner v-if="!loadedReference"></ion-spinner>
        <div class="edit-reference-header" v-if="loadedReference">
          <div class="edit-reference-header-action" @click="$router.back()">
            <ion-icon :icon="chevronBackOutline"/>
          </div>
          <div class="content">
            <span>Referenzprojekt bearbeiten</span>
          </div>
          <span class="next edit-reference-header-action" @click="editReference">Fertig</span>
        </div>
        <div class="edit-reference" v-if="loadedReference">
          <input
            ref="addPhoto"
            type="file"
            accept="image/jpeg, image/png, image/gif"
            placeholder="Choose photos or drop them here..."
            @change="photoInputChanged($event)"
            multiple
            hidden
          />
          <div class="form-field">
            <label>PROJEKT</label>
            <input v-model="title" placeholder="Projektname">
          </div>
          <div class="form-field">
            <label>BENÖTIGTE KOMPETENZEN</label>
            <ion-select cancel-text="Zurück" ok-text="Fertig" @ionChange="setReferenceCompetences" v-model="referenceCompetences" placeholder="Kompetenzen auswählen" multiple>
              <ion-select-option v-for="(competence, index) in competences" :key="index" :value="index">{{competence.name}}</ion-select-option>
            </ion-select>
          </div>
          <div class="form-field">
            <label>FERTIGSTELLUNGSDATUM</label>
            <ion-datetime @ionChange="setReferenceDate" :value="getReferenceDate(referenceDate)" cancelText="Zurück" doneText="Fertig" display-format="DDDD, DD. MMMM YYYY" placeholder="Freitag, 17. Juli 2020" day-names="Montag, Dienstag, Mittowoch, Donnerstag, Freitag, Samstag, Sonntag" month-names="Januar, Februar, März, April, Mai, Juni, Juli, August, September, Oktober, November, Dezember"></ion-datetime>
          </div>
          <div class="form-field">
            <label>BESCHREIBUNG</label>
            <autoexpand-textarea v-model:value="description" placeholder="Text eingeben" />
          </div>
          <div class="form-field images-wrapper">
            <label>FOTOS</label>
            <draggable v-model="photos" :move="preventChangingPositionOfAddButton" class="selected-images-edit" handle=".img-wrapper-drag-edit">
              <div v-for="(photo, index) in photos" class="img-wrapper img-wrapper-drag-edit" :key="index+'-edit'" :style="{background: 'url('+getPhotoURL(photo)+')', backgroundSize: 'cover', backgroundPosition: 'center'}">
                <ion-icon class="img-remove" :icon="closeOutline" @click.stop="photos.splice(index, 1)"/>
              </div>
              <div class="img-wrapper img-wrapper-add" @click="addPhoto">
                <ion-icon class="img-add" :icon="addOutline"/>
              </div>
          </draggable>
          <small class="add-photo-msg" v-if="photos.length">Hinweis: Bleibe mit dem Daumen länger auf einem Foto, um die Darstellungsreihenfolge zu ändern.</small>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonIcon, IonSelect, IonSelectOption, IonDatetime, IonSpinner } from '@ionic/vue'
import generateUUID from '@/utils/generateUUID'
import { closeOutline, addOutline, chevronBackOutline } from 'ionicons/icons'
import toast from '@/mixins/toast'
import loader from '@/mixins/loader'
import fetchingAndGettingGlobalData from '@/mixins/fetchingAndGettingGlobalData'
import photos from '@/mixins/photos'
import { referencesCollection } from '@/firebase'
import { VueDraggableNext } from 'vue-draggable-next'
import AutoexpandTextarea from '@/components/general/autoexpandTextarea'

export default {
  components: { IonPage, IonContent, IonIcon, IonSelect, IonSelectOption, IonDatetime, draggable: VueDraggableNext, AutoexpandTextarea, IonSpinner },
  mixins: [toast, loader, fetchingAndGettingGlobalData, photos],
  data() {
    return {
      title: '',
      description: '',
      photos: [],
      oldReferencePhotos: [],
      referenceCompetences: [],
      referenceDate: new Date(),
      loadedReference: false,
    }
  },
  setup() {
    return { closeOutline, addOutline, chevronBackOutline }
  },
  mounted() {
    this.getData()
  },
  watch: {
    async $route(r) {
      if(r.name === 'EditReference') {
        this.getData()
      }
    },
  },
  methods: {
    preventChangingPositionOfAddButton({relatedContext}){
      return !!relatedContext.element;
    },
    async getData() {
      this.loadedReference = false
      
      // Get competences
      await this.getCompetences()

      // Get reference by id
      await this.getReferenceById(this.$route.params.id)

      this.loadedReference = true
    },
    getPhotoURL(photo) {
      return photo.preview || photo
    },
    async getReferenceById(id) {
      const refSnapshot = await referencesCollection.doc(id).get()
      const reference = {id, ...refSnapshot.data()}
      
      this.title = reference.project || ''
      this.description = reference.description || ''
      this.referenceDate = new Date(reference.date.seconds*1000) || new Date()
      this.referenceCompetences = reference.competences || []
      this.photos = [...reference.photos]

      if(reference.photos) {
        this.oldReferencePhotos = [...reference.photos]
      }

    },
    async editReference() {
      
      if(!this.title.trim()) {
        this.openToast('Bitte gib einen Projektnamen.', 6000)
        return
      }

      if(!this.photos.length) {
        this.openToast('Bitte wähle mindestens ein Foto aus.', 6000)
        return
      }

      await this.startLoader()

      const referenceId = this.$route.params.id

      // Upload photos
      const photoURLs = await this.uploadPhotos(this.photos, referenceId, this.user.token)

      // Update reference to database
      await referencesCollection.doc(referenceId).update({
        project: this.title,
        description: this.description,
        updatedOn: new Date(),
        date: this.referenceDate,
        worker: this.user.id,
        competences: this.referenceCompetences,
        photos: photoURLs
      })

      let segment = {}
       if(this.user && this.user.segment) {
         try {
            segment = JSON.parse(this.user.segment)
         } catch (e) {
            console.log(e)
         }
      }

      window.analytics.track('Reference Updated', {
        name: this.user && this.user.firstname + ' ' + this.user.lastname,
        jobtitle: this.user && this.user.jobtitle,
        ...segment
      })

      // Delete reference photos from storage
      const photosToBeDeleted = this.oldReferencePhotos.filter(p => !photoURLs.includes(p))
      this.deletePhotos(photosToBeDeleted, referenceId)

      this.$router.push({name: 'MyProfile', query: {tab: 'references'}})
      await this.stopLoader()
    },
    setReferenceCompetences(c) {
      this.referenceCompetences = c.target.value
    },
    getReferenceDate(referenceDate) {
      referenceDate = referenceDate || new Date()
      return new Date(referenceDate.getTime()).toDateString()
    },
    setReferenceDate(d) {
      this.referenceDate = d && d.target && d.target.value && new Date(d.target.value) || new Date()
    },
    addPhoto() {
      this.$refs.addPhoto.click()
    },
    photoInputChanged(e) {
      const files = e.target.files

      files.forEach(file => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.addEventListener("load", () => {
          const photo = {id: generateUUID(), file: file, preview: fileReader.result}
          this.photos.push(photo)
        });  
      })
      this.$refs.addPhoto.value = ''
    }
  }
}
</script>

<style lang="sass" scoped>
.edit-reference-header
  .edit-reference-header-action
    display: flex
    ion-icon
      cursor: pointer
      font-size: 22px
      font-weight: 700
  display: flex
  justify-content: space-between
  padding: 52px 23px 10px
  background: var(--dim-gray-dark)
  color: white
  text-align: center
  .content
    margin: 0 10px 14px
    span
      display: block
      font-size: 18px
      font-weight: 700
      line-height: 21px
      margin-bottom: 2px
  .next
    cursor: pointer
    white-space: nowrap
    margin-top: 2px
    font-size: 14px
    text-align: right
    line-height: 16px
    font-weight: 700
    width: 40px

.edit-reference-header-action
  width: 40px

.edit-reference
  padding: 16px 0
  .form-field
    display: flex
    flex-direction: column
    padding: 16px 31px
    label
      text-transform: uppercase
      font-size: 12px
      line-height: 14px
      font-weight: 700
      margin-bottom: 4px
      color: var(--dim-gray-light)
    input, ion-select, ion-datetime, textarea
      resize: none
      outline: 0
      border: 0
      border-bottom: 1px solid #E9EAEC
      font-size: 15px
      padding: 10px 0
      color: var(--dim-gray-dark)
      &::placeholder
        color: var(--dim-gray-dark)
    input
      width: 100%
      -webkit-appearance: none
      -moz-appearance: textfield
      &::-webkit-inner-spin-button, &::-webkit-outer-spin-button
        -webkit-appearance: none
        margin: 0
    ion-select
      --placeholder-opacity: 1

.images-wrapper
  padding: 0 !important
  label
    margin: 16px 31px
  .selected-images-edit
    display: flex
    flex-wrap: wrap
    margin: 3px 20px 20px
    .img-wrapper
      width: calc(33% - 22px)
      height: calc(33vw - 33px)
      margin: 11px
      border-radius: 5px
      background: var(--silver-light)
      position: relative
      .img-remove
        position: absolute
        z-index: 20
        top: -1px
        right: -1px
        color: var(--dim-gray-light)
        padding: 3px
        background: white
        border-bottom-left-radius: 5px
        font-size: 14px
    .img-wrapper-add
      background: white
      border: 1px dashed var(--silver-light)
      .img-add
        position: absolute
        left: 0
        right: 0
        top: 0
        bottom: 0
        margin: auto
        color: var(--silver-dark)
        font-size: 26px
  
.add-photo-msg
  color: var(--silver-dark)
  font-size: 14px
  font-weight: 400
  line-height: 20px
  text-align: center
  display: block
  margin: 0 35px 30px

ion-spinner
  color: var(--indigo-dark)
  margin: 47vh auto 0
  display: block
  transform: scale(0.85)
</style>