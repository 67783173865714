import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      user: null,
      competences: null,
      workers: null,
      activities: [],
      lastLoadedActivity: null,
      hideTabs: false,
      dashboardReferences: [],
      showFullscreen: false,
      fullscreenIndex: 0,
      fullscreenPhotos: [],
      fullscreenWithoutCounter: false,
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setCompetences(state, competences) {
      state.competences = competences
    },
    setWorkers(state, workers) {
      state.workers = workers
    },
    setActivities(state, activities) {
      state.activities = activities
    },
    setLastLoadedActivity(state, activity) {
      state.lastLoadedActivity = activity
    },
    setHideTabs(state, hideTabs) {
      state.hideTabs = hideTabs
    },
    setDashboardReferences(state, dashboardReferences) {
      state.dashboardReferences = dashboardReferences
    },
    setFullscreen(state, {index, photos, hideCounter}) {
      if(index || index === 0) {
        state.fullscreenIndex = index
      }

      if(photos) {
        state.fullscreenPhotos = photos
      }

      state.fullscreenWithoutCounter = !!hideCounter

      state.showFullscreen = true
    },
    hideFullscreen(state) {
      state.showFullscreen = false
    }
  }
})

export default store;
