import { createRouter, createWebHistory } from '@ionic/vue-router'
import Tabs from '../components/general/tabs.vue'
import Dashboard from '@/views/dashboard.vue'
import Login from '@/views/login.vue'
import Profile from '@/views/profile.vue'
import EditProfile from '@/views/edit-profile.vue'
import AddReference from '@/views/add-reference.vue'
import EditReference from '@/views/edit-reference.vue'
import Withdraw from '@/views/withdraw.vue'
import Activities from '@/views/activities.vue'
import RequestReview from '@/views/request-review.vue'
import GiveReview from '@/views/give-review.vue'
import VerifyReview from '@/views/verify-review.vue'
import ReferenceComments from '@/views/reference-comments.vue'

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    component: Tabs,
    children: [
      {
        name: 'Dashboard',
        path: 'dashboard',
        component: Dashboard
      },
      {
        name: 'MyProfile',
        path: 'my-profile',
        component: Profile
      },
      {
        name: 'Profile',
        path: 'profile/:workerId',
        component: Profile
      },
      {
        name: 'EditProfile',
        path: 'profile-edit',
        component: EditProfile
      },
      {
        name: 'AddReference',
        path: 'reference/add',
        component: AddReference
      },
      {
        name: 'EditReference',
        path: 'reference/:id',
        component: EditReference
      },
      {
        name: 'Withdraw',
        path: 'withdraw',
        component: Withdraw
      },
      {
        name: 'RequestReview',
        path: 'request-review',
        component: RequestReview
      },
      {
        name: 'Activities',
        path: 'activities',
        component: Activities
      },
      {
        name: 'ReferenceComments',
        path: '/reference-comments/:referenceId',
        component: ReferenceComments
      }
    ]
  },
  {
    name: 'GiveReview',
    path: '/review/:id',
    component: GiveReview
  },
  {
    name: 'VerifyReview',
    path: '/verify/:id',
    component: VerifyReview
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/dashboard'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
