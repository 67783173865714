import { toastController } from '@ionic/vue';

export default {
    methods: {
        async openToast(message, duration = 5000) {
            const toast = await toastController.create({
                message,
                duration
            })
            return toast.present();
        },
    }
}