<template>
    <div class="no-profile-data">
        <div class="caseIcon">
            <img src="@/assets/helmet.svg">
        </div>
        <h3>Vervollständige dein Profil</h3>
        <p>Dein Profil ist derzeit noch leer. Vervollständige dein Profil, um anderen zu zeigen, über welche Kompetenzen und Erfahrung du verfügst.</p>
        <ion-button @click="$router.push({name: 'EditProfile'})">Bearbeiten</ion-button>
    </div>
</template>

<script>
import { IonButton } from '@ionic/vue'
export default {
  components: {IonButton}
}
</script>

<style lang="sass" scoped>
.no-profile-data
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  text-align: center
  width: 80vw
  max-width: 300px
  margin: 75px auto
  box-sizing: border-box
  .caseIcon
    width: 54px
    img
      width: 100%
      height: auto
  h3, p
    color: var(--dim-gray-dark)
  h3
    font-size: 22px
    margin: 20px 5px
    font-weight: 700
    line-height: 26px
  p
    font-size: 14px
    line-height: 20px
    margin: 0
  ion-button
    margin-top: 40px
    --background: var(--indigo-medium)
</style>