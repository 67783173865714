import { storage } from '@/firebase'
import resizePhoto from '@/services/resizePhoto'

export default {
    methods: {
        async uploadPhotos(photos, docId, token, storagePath = 'references') {
            return Promise.all(
              photos.map(async photo => {
                try {
                  
                  // If this is a url, just return it
                  if(!photo.id && photo.includes('https')) {
                    return photo
                  }

                  const filename = `${photo.id}.${photo.file.name.split('.').pop()}`
                  const path = `${storagePath}/${docId}/${filename}`
        
                  // Await photo upload to store
                  await storage.ref(path).put(photo.file)
        
                  // Call cloud function to resize image
                  const resizedPhoto = await resizePhoto(path, token)
                  const data = await resizedPhoto.json()
                  return data.url
                } catch (err) {
                  return null
                }
              })
            )
        },
        deletePhotos(photos, docId, storagePath = 'references') {
          photos.forEach(photo => {
            // Retrieve filename from public signed URL
            const file = decodeURIComponent(photo)
              .split('?')[0]
              .split('/')
              .pop()
        
            // Delete photo on storage
            storage.ref(`${storagePath}/${docId}/${file}`).delete()
          })
        },
    }
}