<template>
    <div class="profile-tabs">
        <span class="profile-tab" ref="profileTab1" :class="{activeTab: activeTab !== 'references' && activeTab !== 'reviews'}" @click="setActiveTab('profile')">
            Steckbrief
        </span>
        <span class="profile-tab" ref="profileTab2" :class="{activeTab: activeTab === 'reviews', 'profile-tab-disabled': !(isMyProfile || numberOfReviews > 0)}" @click="goToReviews">
            Bewertungen ({{numberOfReviews}})
        </span>
        <span class="profile-tab" ref="profileTab3" :class="{activeTab: activeTab === 'references', 'profile-tab-disabled': !(isMyProfile || numberOfReferences > 0)}" @click="goToReferences">
            Referenzen ({{numberOfReferences}})
        </span>
        <div class="profile-active-below" :style="{left: activeTab === 'reviews' ? secondTab.offsetLeft+'px' : activeTab === 'references' ? thirdTab.offsetLeft+'px' : firstTab.offsetLeft+'px', width: activeTab === 'reviews' ? secondTab.width+'px' : activeTab === 'references' ? thirdTab.width+'px' : firstTab.width+'px' }"></div>
    </div>
</template>

<script>
export default {
    props: ['activeTab', 'setActiveTab', 'numberOfReviews', 'numberOfReferences', 'isMyProfile'],
    data() {
        return {
            firstTab: {
                width: 87,
                offsetLeft: 10
            },
            secondTab: {
                width: 127,
                offsetLeft: 97
            },
            thirdTab: {
                width: 114,
                offsetLeft: 224
            }
        }
    },
    mounted() {
        // Set tab underline based on width of the tabs
        setTimeout(() => {
            const { width: w1, left: l1 } = this.$refs.profileTab1.getBoundingClientRect()
            const { width: w2, left: l2 } = this.$refs.profileTab2.getBoundingClientRect()
            const { width: w3, left: l3 } = this.$refs.profileTab3.getBoundingClientRect()
            this.firstTab = { width: w1, offsetLeft: l1 }
            this.secondTab = { width: w2, offsetLeft: l2 }
            this.thirdTab = { width: w3, offsetLeft: l3 }
        }, 200)
    },
    methods: {
        goToReferences() {
            if(this.isMyProfile || this.numberOfReferences > 0) {
                this.setActiveTab('references')
            }
        },
        goToReviews() {
            if(this.isMyProfile || this.numberOfReviews > 0) {
                this.setActiveTab('reviews')
            }
        }
    }
}
</script>

<style lang="sass" scoped>
.profile-tabs
  display: flex
  border-bottom: 1px solid var(--silver-medium)
  padding: 0 10px
  position: relative
  .profile-tab-disabled
    background: var(--silver-lighter)
  .profile-tab
    font-size: 14px
    line-height: 16.41px
    font-weight: 500
    padding: 15px 12px
    color: var(--silver-dark)
    cursor: pointer
  .activeTab
    color: var(--indigo-medium)
  .profile-active-below
    position: absolute
    bottom: 0
    background: var(--indigo-medium)
    height: 4px
    transition: .25s ease
</style>