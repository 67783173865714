import { firebase, workersCollection } from '@/firebase'
import loader from '@/mixins/loader'

export default {
    mixins: [loader],
    data() {
        return {
          siteLoaded: false,
        }
    },
    computed: {
        user() {
          return this.$store.state.user
        },
        activities() {
          return this.$store.state.activities
        }
    },
    async created() {
        this.startLoader()

        firebase.auth().onAuthStateChanged(async user => {
          if(user) {
            const { docs: [ doc ] } = await workersCollection.where('phone', '==', user.phoneNumber).get()
            const token = await user.getIdToken()
            const userData = { id: doc.id, token, ...doc.data()}

            let segment = {}
            if(userData && userData.segment) {
              try {
                  segment = JSON.parse(userData.segment)
              } catch (e) {
                  console.log(e)
              }
            }

            // Analytics identify
            window.analytics.identify(userData.id, {
              name: userData && userData.firstname + ' ' + userData.lastname,
              jobtitle: userData && userData.jobtitle,
              ...segment
            })
            
            // Listen for new activities
            workersCollection
            .doc(doc.id)
            .collection('activities').orderBy('createdOn', 'desc').limit(30).onSnapshot(snapshot => {
              if(this.activities && !this.activities.length && snapshot.docs) {
                this.$store.commit('setActivities', snapshot.docs.map(d => ({id: d.id, ...d.data()})))

                this.$store.commit('setLastLoadedActivity', snapshot.docs.length ? snapshot.docs[snapshot.docs.length-1] : 'ALL_ACTIVITIES_LOADED')
              } else if(this.activities && this.activities.length && snapshot.docs && snapshot.docs.length && snapshot.docs[0].id !== this.activities[0].id) {
                this.$store.commit('setActivities', [{id: snapshot.docs[0].id, ...snapshot.docs[0].data()}, ...this.activities])
              }
            })

            if(userData) {
              this.$store.commit('setUser', userData)
            } else {
              this.$store.commit('setUser', null)
              if(this.$route.name !== 'GiveReview' && this.$route.name !== 'VerifyReview') this.$router.push({name: 'Login'})
            }
          }

          await new Promise(resolve => {
            setTimeout(() => {
              resolve()
            }, 200)
          })

          if(user && this.$route.name === 'Login') {
            this.$router.push({name: 'Dashboard'})
          } else if(!user && this.$route.name !== 'Login' && this.$route.name !== 'GiveReview' && this.$route.name !== 'VerifyReview') {
            this.$router.push({name: 'Login'})
          }
          
          setTimeout(() => {
            this.stopLoader()
            this.siteLoaded = true
          }, 500)
        })
    },
    methods: {
        async updateUserLastActivity() {
            const workers = await workersCollection.where("phone", "==", this.user.phone).get()
            if(workers.docs.length) {
              workersCollection.doc(workers.docs[0].id).update({lastActivity: new Date()})
            }
        },
    },
    watch: {
        siteLoaded(v) {
          if(v) {
            // Analytics page
            window.analytics.page(this.$route.name)

            let segment = {}
            if(this.user && this.user.segment) {
              try {
                  segment = JSON.parse(this.user.segment)
              } catch (e) {
                  console.log(e)
              }
            }

            if(this.$route.name === 'Withdraw') {
              window.analytics.track('Withdrawal Request Form Opened', {
                name: this.user && this.user.firstname + ' ' + this.user.lastname,
                jobtitle: this.user && this.user.jobtitle,
                ...segment
              })
            }

            if(this.$route.name === 'RequestReview') {
              window.analytics.track('Review Request Step 1', {
                name: this.user && this.user.firstname + ' ' + this.user.lastname,
                jobtitle: this.user && this.user.jobtitle,
                ...segment
              })
            }

            if(this.$route.name === 'GiveReview') {
              window.analytics.track('Review Request Opened', {requestId: this.$route.params.id, type: this.$route.query.type})
            }
          }
        },
        $route(r) {
          if(this.user) {
            this.updateUserLastActivity()
          }

          if(r.name === 'Login' && this.user || r.name === 'Dashboard' && !this.user) {
            this.siteLoaded = false
            this.$router.go()
          } else {
            // Analytics page
            window.analytics.page(r.name)

            let segment = {}
            if(this.user && this.user.segment) {
              try {
                  segment = JSON.parse(this.user.segment)
              } catch (e) {
                  console.log(e)
              }
            }

            if(r.name === 'Withdraw') {
              window.analytics.track('Withdrawal Request Form Opened', {
                name: this.user && this.user.firstname + ' ' + this.user.lastname,
                jobtitle: this.user && this.user.jobtitle,
                ...segment
              })
            }

            if(r.name === 'RequestReview') {
              window.analytics.track('Review Request Step 1', {
                name: this.user && this.user.firstname + ' ' + this.user.lastname,
                jobtitle: this.user && this.user.jobtitle,
                ...segment
              })
            }

            if(r.name === 'GiveReview') {
              window.analytics.track('Review Request Opened', {requestId: this.$route.params.id, type: this.$route.query.type})
            }
          }
        }
    }
}