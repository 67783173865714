<template>
  <ion-page>
    <div ref="referenceCommentsWrapper" class="reference-comments-wrapper">
      <div class="reference-wrapper" v-if="reference">
        <referenceCard @scroll-to-comments="scrollToComments()" @focus-add-comment-input="addCommentFocus" :isCommentsView="true" :isLastReference="true" :reference="reference" :userId="user && user.id" @set-likes="setLikes($event)" :getWorkerNameById="getWorkerNameById"/>
        <div class="comments">
          <div class="comment" v-for="comment in referenceComments" :key="comment.id">
            <div class="comment-above">
              <div @click="comment.workerId && (user.id !== comment.workerId ? $router.push({name: 'Profile', params: {workerId: comment.workerId}}) : $router.push({name: 'MyProfile'}))" class="img-wrapper" :style=" {background: 'url('+getWorkerPhoto(comment.workerId)+')', backgroundSize: 'cover', backgroundPosition: 'center'}">
              </div>
              <ion-label>
                <h3 @click="comment.workerId && (user.id !== comment.workerId ? $router.push({name: 'Profile', params: {workerId: comment.workerId}}) : $router.push({name: 'MyProfile'}))">{{getWorkerName(comment.workerId)}}</h3>
                <p>{{commentDate(comment.createdOn)}}</p>
              </ion-label>
              <ion-icon :icon="trashOutline" v-if="user && commenz && comment.workerId === user.id" @click="triggerDeleteCommentModal(comment.id)"/>
            </div>
            <div class="comment-message">
              <span>{{comment.message}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="add-comment" v-if="reference">
        <div class="user-img" v-if="userPhoto" :style="{background: 'url('+userPhoto+')', backgroundSize: 'cover', backgroundPosition: 'center'}"></div>
        <div class="user-img" v-if="!userPhoto">
          <span>{{userInitials}}</span>
        </div>
        <autoexpand-textarea ref="addCommentField" v-model:value="comment" placeholder="Kommentieren ..." />
        <ion-icon :icon="paperPlaneOutline" @click="addComment"/>
      </div>
      <ion-spinner v-if="loading && !reference"></ion-spinner>
    </div>
  </ion-page>
</template>

<script>
import { referencesCollection } from '@/firebase'
import {
  IonPage,
  IonSpinner,
  IonIcon,
  IonLabel,
  alertController,
} from '@ionic/vue'
import { paperPlaneOutline, trashOutline } from 'ionicons/icons'
import referenceCard from '@/components/general/referenceCard.vue'
import fetchingAndGettingGlobalData from '@/mixins/fetchingAndGettingGlobalData'
import AutoexpandTextarea from '@/components/general/autoexpandTextarea'
import activities from '@/mixins/activities'
import moment from "moment"
moment.locale('de-ch')

export default {
  mixins: [fetchingAndGettingGlobalData, activities],
  components: {
    IonPage,
    IonSpinner,
    IonIcon,
    IonLabel,
    referenceCard,
    AutoexpandTextarea,
  },
  data() {
    return {
      initialLoading: true,
      loading: true,
      lastLoadedDocument: null,
      reference: null,
      referenceComments: [],
      comment: ''
    }
  },
  computed: {
    userPhoto() {
      return this.user && this.user.photo
    },
    userInitials() {
      return this.user && this.user.firstname && this.user.lastname && (this.user.firstname[0]+this.user.lastname[0]) || 'FM'
    },
    dashboardReferences() {
      return this.$store.state.dashboardReferences
    }
  },
  async mounted() {
    this.$store.commit('setHideTabs', true)
    await this.fetchData()
    if(this.$route.query.scrollToComments) {
      this.scrollToComments()
    }

    let segment = {}
    if(this.user && this.user.segment) {
      try {
          segment = JSON.parse(this.user.segment)
      } catch (e) {
          console.log(e)
      }
    }

    window.analytics.track('Reference Comments Opened', {
      name: this.user && this.user.firstname + ' ' + this.user.lastname,
      jobtitle: this.user && this.user.jobtitle,
      ...segment
    })

    this.$refs.referenceCommentsWrapper.addEventListener('scroll', async e => {
      // Pagination
      if(((e.target.scrollTop + document.body.scrollTop) / (e.target.scrollHeight - e.target.clientHeight) * 100) > 95) {
        await this.loadMoreComments()
      }
    })
  },
  watch: {
    async $route(r) {
      if(r.name === 'ReferenceComments') {
        this.$store.commit('setHideTabs', true)
        await this.fetchData()
        if(this.$route.query.scrollToComments) {
          this.scrollToComments()
        }

        let segment = {}
        if(this.user && this.user.segment) {
          try {
              segment = JSON.parse(this.user.segment)
          } catch (e) {
              console.log(e)
          }
        }

        window.analytics.track('Reference Comments Opened', {
          name: this.user && this.user.firstname + ' ' + this.user.lastname,
          jobtitle: this.user && this.user.jobtitle,
          ...segment
        })
      } else {
        this.$store.commit('setHideTabs', false)

        const newReferences = this.dashboardReferences.map(r => r.id !== this.reference.id ? r : this.reference)
        this.$store.commit('setDashboardReferences', newReferences)
      }
    }
  },
  methods: {
      scrollToComments() {
        setTimeout(() => {
          this.$refs.referenceCommentsWrapper.scrollTo(0, 350)
        }, 100)
      },
      addCommentFocus() {
        this.$refs.addCommentField.$el.focus()
      },
      commentDate(createdOn) {
            const commentDate = createdOn.seconds * 1000
            const currentDate = new Date().getTime()
            const timePassed = currentDate - commentDate
            const secs = timePassed / 1000

            let formattedDate
            if(secs < 60) {
                formattedDate = 'Vor 1 Minute'
            } else if(secs >= 60 && secs < 3600) {
                formattedDate = `Vor ${Math.round(secs/60)} Minuten`
            } else if(secs >= 3600 && secs < 3600*21) {
                formattedDate = moment(commentDate).startOf('hour').fromNow()
            } else if(secs >= 3600*21 && secs < 3600*24*2) {
                formattedDate = 'Vor 1 Tag'
            } else if(secs >= 3600*24*2 && secs < 3600*24*7) {
                formattedDate = `Vor ${Math.round(secs/60/60/24)} Tagen`
            } else if(secs >= 3600*24*7 && secs < 3600*24*8) {
                formattedDate = 'Vor 1 Woche'
            } else {
                formattedDate = moment(commentDate).format('Do MMMM YYYY, HH:MM')
            }
            return formattedDate
    },
    async removeComment(commentId) {
      await referencesCollection.doc(this.reference && this.reference.id).collection('comments').doc(commentId).delete()
      this.referenceComments = this.referenceComments.filter(c => c.id !== commentId)
      await referencesCollection.doc(this.reference && this.reference.id).update({
        commentCount: this.reference.commentCount-1
      })

      if(this.reference) {
        this.reference.commentCount--
      }

      let segment = {}
      if(this.user && this.user.segment) {
        try {
            segment = JSON.parse(this.user.segment)
        } catch (e) {
            console.log(e)
        }
      }

      window.analytics.track('Reference Comment Deleted', {
        name: this.user && this.user.firstname + ' ' + this.user.lastname,
        jobtitle: this.user && this.user.jobtitle,
        ...segment
      })
    },
    async triggerDeleteCommentModal(commentId) {
            const alert = await alertController
                .create({
                cssClass: 'delete-reference-alert',
                message: 'Möchtest du diesen Kommentar wirklich löschen?',
                buttons: [
                    {
                        text: 'Abbrechen',
                        role: 'cancel',
                    },
                    {
                        text: 'Ja',
                        handler: () => {
                            this.removeComment(commentId)
                        },
                    },
                ],
                });
            return alert.present();
    },
    getWorkerName(workerId) {
      const worker = this.workers && this.workers[workerId]
      return worker && worker.firstname + ' ' + worker.lastname
    },
    getWorkerPhoto(workerId) {
      const worker = this.workers && this.workers[workerId]
      return worker && worker.photo || require("@/assets/reference-avatar.svg")
    },
    async fetchData() {
      // Reset values
      this.reference = null
      this.referenceComments = []
      this.lastLoadedDocument = null
      this.initialLoading = true
      this.loading = true

      // Get user data
      await this.getUser()
      
      // Get workers and competences to populate references
      await this.getWorkers()
      await this.getCompetences()
      await this.getReferenceById(this.$route.params.referenceId)
    },
    getWorkerNameById(id) {
      const worker = this.workers && this.workers[id]
      return worker && worker.firstname && worker.lastname && worker.firstname + ' ' + worker.lastname || 'Gelöschtes Profil'
    },
    setLikes({ likes }) {
      this.reference.likes = likes
    },
    async getReferenceById(id) {
      const ref = await referencesCollection.doc(id).get()

      if (!ref.exists) {
        this.$router.push({name: 'Dashboard'})
        return
      }

      const refComments = await referencesCollection.doc(id).collection('comments').orderBy('createdOn', 'desc').limit(10).get()
      const reference = {id: ref.id, ...ref.data()}
      reference.competences = reference.competences && reference.competences.length ? reference.competences.filter(c => this.competences[c]).map(c => this.competences[c]) : []
      reference.worker = this.workers && this.workers[reference.worker]
      this.reference = reference
      this.referenceComments = refComments.docs.map(c => ({id: c.id, ...c.data()}))
      this.lastLoadedDocument = refComments.docs.length ? refComments.docs[refComments.docs.length-1] : 'ALL_COMMENTS_LOADED'
    },
    async loadMoreComments() {
      // If there's no more references or loading is already in progress and it's not initial loading
      if(this.lastLoadedDocument === 'ALL_COMMENTS_LOADED' || (this.loading && !this.initialLoading) || this.referenceComments.length < 10) {
        return
      }

      this.initialLoading = false
      this.loading = true

      if(!(this.lastLoadedDocument && !this.initialLoading)) {
        return
      }

      let snapshot = await referencesCollection.doc(this.reference && this.reference.id).collection('comments').orderBy('createdOn', 'desc').startAfter(this.lastLoadedDocument).limit(10).get()

      const comments = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))

      this.referenceComments = [...this.referenceComments, ...comments]
      this.lastLoadedDocument = snapshot.docs.length ? snapshot.docs[snapshot.docs.length-1] : 'ALL_COMMENTS_LOADED'

      this.loading = false
    },
    async addComment() {
      if(!this.comment) {
        return
      }

      const comment = {
        message: this.comment,
        workerId: this.user && this.user.id,
        createdOn: new Date()
      }

      this.comment = ''
      const {id: commentId} = await referencesCollection.doc(this.reference && this.reference.id).collection('comments').add(comment)
      this.referenceComments.unshift({id: commentId, ...comment, createdOn: { seconds: Math.round(new Date().getTime()/1000)}})

      if(this.reference && this.reference.id) {
        await referencesCollection.doc(this.reference.id).update({
          commentCount: this.reference.commentCount ? this.reference.commentCount + 1 : 1
        })
      }


      this.reference.commentCount = this.reference.commentCount ? this.reference.commentCount + 1 : 1

      if(this.user && this.user.id !== this.reference.worker.id) {
        this.addActivity({
            type: 'referenceCommented',
            createdOn: new Date(),
            referenceId: this.reference && this.reference.id,
            referenceName: this.reference && this.reference.project,
            workerId: this.user && this.user.id,
            workerName: this.user && this.user.firstname + ' ' + this.user.lastname
        }, this.reference.worker && (this.reference.worker.id || this.reference.worker))
      }

      let segment = {}
      if(this.user && this.user.segment) {
        try {
            segment = JSON.parse(this.user.segment)
        } catch (e) {
            console.log(e)
        }
      }

      window.analytics.track('Reference Comment Added', {
        name: this.user && this.user.firstname + ' ' + this.user.lastname,
        jobtitle: this.user && this.user.jobtitle,
        ...segment
      })
    }
  },
  setup() {
    return { paperPlaneOutline, trashOutline }
  }
}
</script>

<style lang="sass" scoped>
.reference-comments-wrapper
  background: white
  overflow-y: scroll
  height: 100vh
  padding-bottom: 220px
  scroll-behavior: smooth

.comment
  margin: 15px 5vw
  .comment-above
    display: flex
    align-items: center
    .img-wrapper
        cursor: pointer
        overflow: hidden
        border-radius: 50%
        margin-right: 10px
        width: 40px
        height: 40px
    ion-label
      h3
        cursor: pointer
        color: var(--indigo-medium)
        font-size: 14px
        margin-bottom: 1px
        font-weight: 700
        line-height: 16px
      p
        color: var(--dim-gray-light)
        font-size: 13px
        line-height: 16px
        margin: 0
        text-transform: capitalize
    ion-icon
      margin-left: auto
      color: var(--dim-gray-light)
      cursor: pointer
      margin-bottom: 17px
      margin-right: 5px
      font-size: 17px
      min-width: 17px
  .comment-message
    margin-top: 5px
    margin-left: 50px
    font-size: 13px
    color: var(--dim-gray-dark)
    span
      line-height: 20px

.add-comment
  position: fixed
  max-height: 65px
  z-index: 1000
  bottom: 0
  left: 0
  right: 0
  display: flex
  align-items: center
  background: white
  border-top: 1px solid var(--silver-medium)
  padding: 8px 16px
  .user-img
    min-width: 45px
    min-height: 45px
    width: 45px
    height: 45px
    border-radius: 50%
    overflow: hidden
    position: relative
    background: var(--indigo-lighter)
    span
      position: absolute
      top: -2px
      right: 0
      left: 2px
      bottom: 0
      margin: auto
      text-align: center
      height: 28%
      font-size: 14px
      color: var(--indigo-medium)
      letter-spacing: 2px
  textarea
    border: none
    margin: 0 10px
    padding-top: 18px
    font-size: 14px
    width: 100%
    max-height: 50px
    color: var(--dim-gray-dark)
    &::-webkit-input-placeholder
      color: var(--dim-gray-dark)
      opacity: 1
    line-height: 16px
    letter-spacing: 0.03em
  ion-icon
    cursor: pointer
    margin-left: auto
    color: var(--indigo-medium)
    min-width: 23px
    font-size: 23px

ion-spinner
  color: var(--indigo-dark)
  margin: 280px auto 0
  display: block
  transform: scale(0.85)
</style>