<template>
  <ion-page>
    <ion-content>
        <ion-spinner v-if="loading"></ion-spinner>
        <div class="edit-profile-header" v-if="!loading">
          <div class="edit-profile-header-action" @click="$router.back()">
            <ion-icon :icon="chevronBackOutline"/>
          </div>
          <div class="content">
            <span>Mein Profil</span>
          </div>
          <span class="next edit-profile-header-action" @click="editProfile">Fertig</span>
        </div>
        <div class="edit-profile" v-if="!loading">
          <input
            ref="addPhoto"
            type="file"
            accept="image/jpeg, image/png, image/gif"
            placeholder="Choose photos or drop them here..."
            @change="photoInputChanged($event)"
            hidden
          />
          <div class="profile-image" v-if="photo">
            <div class="img-wrapper" :style="{background: 'url('+getPhotoURL(photo)+')', backgroundSize: 'cover', backgroundPosition: 'center'}">
            </div>
            <ion-icon class="edit-image" :icon="pencilOutline" @click="addPhoto"/>
          </div>
          <div class="profile-image" v-else>
            <span>{{userInitials}}</span>
            <ion-icon class="edit-image" :icon="pencilOutline" @click="addPhoto"/>
          </div>
          <div class="form-field">
            <label>JOBTITLE</label>
            <input v-model="jobtitle" placeholder="Jobtitle">
          </div>
          <div class="form-field">
            <label>BESCHREIBUNG</label>
            <autoexpand-textarea v-model:value="profile" placeholder="Text eingeben" />
          </div>
          <div class="form-field">
            <label>KOMPETENZEN</label>
            <ion-select cancel-text="Zurück" ok-text="Fertig" @ionChange="setUserCompetences" v-model="userCompetences" placeholder="Kompetenzen auswählen" multiple>
              <ion-select-option v-for="(competence, index) in competences" :key="index" :value="index">{{competence.name}}</ion-select-option>
            </ion-select>
          </div>
          <div class="form-field">
            <label>AUSBILDUNGEN</label>
            <autoexpand-textarea v-model:value="education" placeholder="Ausbildungen eingeben" />
          </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonIcon, IonSelect, IonSelectOption, IonSpinner } from '@ionic/vue'
import generateUUID from '@/utils/generateUUID'
import { pencilOutline, addOutline, chevronBackOutline } from 'ionicons/icons'
import toast from '@/mixins/toast'
import loader from '@/mixins/loader'
import fetchingAndGettingGlobalData from '@/mixins/fetchingAndGettingGlobalData'
import photos from '@/mixins/photos'
import { workersCollection } from '@/firebase'
import AutoexpandTextarea from '@/components/general/autoexpandTextarea'

export default {
  components: { IonPage, IonContent, IonIcon, IonSelect, IonSelectOption, AutoexpandTextarea, IonSpinner },
  mixins: [toast, loader, fetchingAndGettingGlobalData, photos],
  data() {
    return {
      jobtitle: '',
      profile: '',
      education: '',
      photo: '',
      oldPhoto: '',
      userCompetences: [],
      firstname: '',
      lastname: '',
      loading: true,
    }
  },
  setup() {
    return { pencilOutline, addOutline, chevronBackOutline }
  },
  async mounted() {
      this.getData()
  },
  computed: {
    userInitials() {
        return this.firstname && this.lastname && (this.firstname[0]+this.lastname[0]) || 'FM'
    },
  },
  watch: {
    async $route(r) {
      if(r.name === 'EditProfile') {
        this.getData()
      }
    },
  },
  methods: {
    async getData() {
        this.loading = true

        // Get competences
        await this.getCompetences()

        // Get reference by id
        await this.prefillEditProfileForm(this.user && this.user.id)

        this.loading = false
    },
    getPhotoURL(photo) {
      return photo.preview || photo
    },
    async prefillEditProfileForm(id) {
      const userSnapshot = await workersCollection.doc(id).get()
      const user = {id, ...userSnapshot.data()}
      
      this.jobtitle = user.jobtitle || ''
      this.profile = user.profile || ''
      this.education = user.education || ''
      this.userCompetences = user.competences && user.competences.map(c => c.id) || []
      this.photo = user.photo || ''
      this.oldPhoto = user.photo || ''
      this.firstname = user.firstname || ''
      this.lastname = user.lastname || ''

    },
    async editProfile() {
      
      await this.startLoader()

      const userId = this.user && this.user.id

      // Upload photo
      const [ photoURL ] = await this.uploadPhotos([this.photo], this.user && this.user.id, this.user.token, 'workers')

      // Update user to database
      await workersCollection.doc(userId).update({
        jobtitle: this.jobtitle,
        profile: this.profile,
        education: this.education,
        competences: this.userCompetences.map(c => ({id: c})),
        photo: photoURL
      })

      let segment = {}
       if(this.user && this.user.segment) {
         try {
            segment = JSON.parse(this.user.segment)
         } catch (e) {
            console.log(e)
         }
      }

      window.analytics.track('Profile Updated', {
          name: this.user && this.user.firstname + ' ' + this.user.lastname,
          jobtitle: this.user && this.user.jobtitle,
          ...segment
      })

      // Delete removed photos from storage
      if(this.oldPhoto && this.oldPhoto !== photoURL) {
          this.deletePhotos([this.oldPhoto], this.user && this.user.id, 'workers')
      }

      await this.stopLoader()
      this.$router.back()
    },
    setUserCompetences(c) {
      this.userCompetences = c.target.value
    },
    addPhoto() {
      this.$refs.addPhoto.click()
    },
    photoInputChanged(e) {
      const file = e.target.files[0]

      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.addEventListener("load", () => {
        const photo = {id: generateUUID(), file: file, preview: fileReader.result}
        this.photo = photo
      });  
      this.$refs.addPhoto.value = ''
    }
  }
}
</script>

<style lang="sass" scoped>
.edit-profile-header
  .edit-profile-header-action
    display: flex
    ion-icon
      cursor: pointer
      font-size: 22px
      font-weight: 700
  display: flex
  justify-content: space-between
  padding: 52px 23px 10px
  background: var(--dim-gray-dark)
  color: white
  text-align: center
  .content
    margin: 0 10px 14px
    span
      display: block
      font-size: 18px
      font-weight: 700
      line-height: 21px
      margin-bottom: 2px
  .next
    cursor: pointer
    white-space: nowrap
    margin-top: 2px
    font-size: 14px
    text-align: right
    line-height: 16px
    font-weight: 700
    width: 40px

.edit-profile-header-action
  width: 40px

.edit-profile
  padding: 16px 0 36px
  .profile-image
    width: 100px
    height: 100px
    margin: 30px auto 15px
    position: relative
    display: flex
    justify-content: center
    align-items: center
    background: var(--indigo-lighter)
    border-radius: 50%
    .img-wrapper
      width: 100%
      height: 100%
      overflow: hidden
      border-radius: 50%
      img
        object-fit: cover
        min-width: 100%
        min-height: 100%
    span
        position: absolute
        top: -2px
        right: 0
        left: 0
        bottom: 0
        margin: auto
        text-align: center
        height: 30%
        font-size: 28px
        color: var(--indigo-medium)
        letter-spacing: 2px
    .edit-image
        position: absolute
        right: 0
        bottom: 0
        background: var(--indigo-dark)
        color: white
        padding: 7px
        border-radius: 50%
        font-size: 20px
        --ionicon-stroke-width: 42px
  .form-field
    display: flex
    flex-direction: column
    padding: 16px 31px
    label
      text-transform: uppercase
      font-size: 12px
      line-height: 14px
      font-weight: 700
      margin-bottom: 4px
      color: var(--dim-gray-light)
    input, ion-select, textarea
      resize: none
      outline: 0
      border: 0
      border-bottom: 1px solid #E9EAEC
      font-size: 15px
      padding: 10px 0
      color: var(--dim-gray-dark)
      &::placeholder
        color: var(--dim-gray-dark)
    input
      width: 100%
      -webkit-appearance: none
      -moz-appearance: textfield
      &::-webkit-inner-spin-button, &::-webkit-outer-spin-button
        -webkit-appearance: none
        margin: 0
    ion-select
      --placeholder-opacity: 1

.images-wrapper
  padding: 0 !important
  label
    margin: 16px 31px
  .selected-images
    display: flex
    flex-wrap: wrap
    margin: 3px 20px 20px
    .img-wrapper
      margin-bottom: 20px
      width: calc(33% - 22px)
      height: calc(33vw - 33px)
      margin: 11px
      max-width: 125px
      max-height: 125px
      border-radius: 5px
      overflow: hidden
      background: var(--silver-light)
      position: relative
      img
        max-width: 100%
        display: block
        margin: auto
        height: auto
      .img-remove
        position: absolute
        z-index: 20
        top: 0
        right: 0
        color: var(--dim-gray-light)
        background: white
        padding: 3px
        border-bottom-left-radius: 5px
        font-size: 14px
    .img-wrapper-add
      background: white
      border: 1px dashed var(--silver-light)
      .img-add
        position: absolute
        left: 0
        right: 0
        top: 0
        bottom: 0
        margin: auto
        color: var(--silver-dark)
        font-size: 26px

ion-spinner
  color: var(--indigo-dark)
  margin: 47vh auto 0
  display: block
  transform: scale(0.85)
</style>