<template>
    <ion-page>
      <ion-tabs>
        <div class="tabs-wrapper" v-click-away="closeAdd" v-if="!hideTabs">
        <transition name="fade">
          <div class="add-pop-up" v-if="showAdd">
              <div class="arrow"></div>
              <div class="add-pop-up-item ion-activatable ripple-parent" @click="$router.push({name: 'AddReference'})">
                <ion-icon :icon="briefcaseOutline" />
                <span>Referenzprojekt hinzufügen</span>
                <ion-ripple-effect></ion-ripple-effect>
              </div>
              <div class="add-pop-up-item ion-activatable ripple-parent" @click="$router.push({name: 'RequestReview'})">
                <ion-icon :icon="starOutline" />
                <span>Bewertung einholen</span>
                <ion-ripple-effect></ion-ripple-effect>
              </div>
          </div>
        </transition>
          <div class="icon-button ion-activatable ripple-parent" @click="showAdd = !showAdd" :style="{transform: showAdd ? 'rotateZ(45deg)' : ''}" v-if="$route.name !== 'AddReference' && $route.name !== 'EditReference' && $route.name !== 'RequestReview'">
            <ion-icon :icon="addOutline" />
            <ion-ripple-effect />
          </div>
          <ion-tab-bar>
            <ion-tab-button @click="$router.push({name: 'Dashboard'})" :selected="$route.name === 'Dashboard'">
              <ion-icon :icon="newspaperOutline" />
              <ion-label>Dashboard</ion-label>
            </ion-tab-button>
            <ion-tab-button @click="$router.push({name: 'MyProfile'})" :selected="$route.name === 'MyProfile'">
              <ion-icon :icon="personOutline" />
              <ion-label>Profil</ion-label>
            </ion-tab-button>
          </ion-tab-bar>
        </div>
      </ion-tabs>
    </ion-page>
</template>

<script>
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRippleEffect  } from '@ionic/vue'
import { newspaperOutline, addOutline, briefcaseOutline, starOutline, personOutline } from 'ionicons/icons'

export default {
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRippleEffect },
  methods: {
    closeAdd() {
      this.showAdd = false
    },
    routeNameIs(r) {
      return this.$route.name === r
    }
  },
  computed: {
    hideTabs() {
      return this.$store.state.hideTabs
    }
  },
  data() {
    return {
      showAdd: false
    }
  },
  watch: {
    $route() {
      this.closeAdd()
    }
  },
  setup() {
    return {
      newspaperOutline,
      addOutline,
      briefcaseOutline,
      starOutline,
      personOutline
    }
  },
}
</script>

<style lang="sass" scoped>
ion-tab-button
  --color-selected: var(--indigo-medium)

.tab-selected
  font-weight: 700

.worker-icon
  width: 27px
  height: 27px

.tabs-wrapper
  cursor: pointer
  position: relative
  .icon-button
    min-width: 0 !important
    background: var(--indigo-medium)
    transition: .25s ease
    width: 55px
    height: 55px
    border-radius: 50%
    overflow: hidden
    position: absolute
    top: -30px
    left: 0
    right: 0
    margin: auto
    z-index: 10000
    display: flex
    justify-content: center
    align-items: center
    ion-icon
      font-size: 23px
      --ionicon-stroke-width: 50px
      color: white
  .add-pop-up
    position: absolute
    z-index: 100
    top: -160px
    width: 320px
    max-width: 90vw
    left: 0
    right: 0
    margin: auto
    background: var(--dim-gray-dark)
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px
    border-radius: 10px
    ion-icon
      font-size: 23px
      color: white
    .arrow
        width: 15px
        height: 15px
        background: var(--dim-gray-dark)
        transform: rotateZ(45deg)
        position: absolute
        z-index: 1
        right: 0
        left: 0
        margin: auto
        bottom: -7px
    .ripple-parent
      position: relative
      overflow: hidden
      padding-left: 20px
      padding-right: 20px
      border-radius: 10px
      &:nth-child(2)
        padding-top: 10px
      &:nth-child(3)
        padding-bottom: 10px
    .add-pop-up-item
      display: flex
      align-items: center
      overflow: hidden
      position: relative
      z-index: 2
      span
          padding: 12px 15px
          display: block
          font-size: 16px
          font-weight: 500
          text-transform: none
          color: white
</style>