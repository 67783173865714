<template>
  <ion-page>
    <ion-content>
      <ion-spinner v-if="loadingScreen"></ion-spinner>
      <div class="is-verified" v-if="!loadingScreen && !isExpired">
        <img src="@/assets/chat-check.svg" alt="">
        <h3>Bewertung bestätigt</h3>
        <p>Vielen Dank, dass Sie für <strong>{{workerName}}</strong> eine Bewertung abgegeben haben. Damit helfen Sie die Qualität und Transparenz auf den Baustellen zu erhöhen.</p>      
      </div>
      <div class="is-expired" v-if="!loadingScreen && isExpired">
        <img src="@/assets/is-expired.svg" alt="">
        <h3>Bestätigungslink ungültig</h3>
        <p>Der Bestätigungslink ist ungültig oder die Bewertung wurde bereits bestätigt.</p>      
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonSpinner } from '@ionic/vue'
import loader from '@/mixins/loader'
import { verifyReview } from '@/services/publicReviewForm'

export default {
  components: { IonPage, IonContent, IonSpinner },
  mixins: [loader],
  async mounted() {
      try {
          const verifiedReview = await verifyReview(this.$route.params.id)
          if(verifiedReview.status === 200) {
              const { workerName } = await verifiedReview.json()
              this.workerName = workerName
              window.analytics.track('Review Verified')
          } else {
            this.isExpired = true
          }
      } catch (e) {
        this.isExpired = true
      }
      this.loadingScreen = false
  },
  data() {
    return {
      workerName: '',
      loadingScreen: true,
      isExpired: false,
    }
  },
}
</script>

<style lang="sass" scoped>
.is-verified, .is-expired
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  height: calc(100vh - 60px)
  margin: 0
  img
    width: 65px
    height: auto
  h3, p
    text-align: center
  h3
    margin: 29px 40px 7px
    font-size: 22px
    line-height: 24px
    font-weight: 700
    color: var(--dim-gray-dark)
  p
    margin: 15px 30px 25px
    font-size: 14px
    font-weight: 400
    line-height: 20px
    color: var(--dim-gray-dark)
  span
    font-size: 14px
    color: var(--dim-gray-light)
    text-decoration: underline
    cursor: pointer

ion-spinner
  color: var(--indigo-dark)
  margin: 47vh auto 0
  display: block
  transform: scale(0.85)
</style>