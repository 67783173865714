<template>
    <div class="review" :class="{'review-unverified': !review.verified}">
        <span class="unverified">Unverifiziert</span>
        <div class="top">
            <div class="details">
                <span class="title">
                    <strong v-if="review.fullname">{{review.fullname}},</strong> <span v-if="review.company">{{review.company}} für das Projekt </span><strong v-if="review.project">{{review.project}}</strong>
                </span>
                <div class="details-below">
                    <div class="rating-wrapper" v-click-away="closeRatingDropdown" @click="showRatingDropdown = !showRatingDropdown">
                        <ion-icon :icon="star" />
                        <span class="rating-score">{{averageRatingPerReview(review)}}</span>
                        <transition name="fade">
                            <div class="rating-skills-dropdown" :class="{isLastRating}" v-if="showRatingDropdown">
                                <div class="arrow"></div>
                                <div class="rating-single" v-for="category in review.categories" :key="category.id">
                                    <span>{{category.name}}</span>
                                    <span>{{category.rating || 0}}</span>
                                </div>
                            </div>
                        </transition>
                    </div>
                    <span class="date">{{formattedDate(review)}}</span>
                </div>
            </div>
            <!-- <div class="recommend" v-show="review && review.recommend">
                <img src="@/assets/like-white.svg">
            </div> -->
        </div>
        <span class="comment" v-if="review.comment">{{review.comment}}</span>
        <div class="border-review" v-if="showSeparator"></div>
    </div>
</template>

<style lang="sass" scoped>
.review
    margin: 23px 0
    .unverified
        display: none
        background: var(--yellow-medium)
        color: var(--yellow-dark)
        text-transform: uppercase
        font-size: 10px
        border-radius: 20px
        font-weight: 700
        line-height: 20px
        letter-spacing: .6px
        padding: 2px 16px
        margin: 11px 0
    .top
        display: flex
        justify-content: space-between
        .details
            .title
                font-size: 13px
                font-weight: 400
                line-height: 16px
                color: var(--dim-gray-dark)
            .details-below
                display: flex
                align-items: center
                margin: 3px 0 10px
                .rating-wrapper
                    cursor: pointer
                    display: flex
                    align-items: center
                    position: relative
                    .rating-skills-dropdown
                        position: absolute
                        z-index: 100
                        left: 0
                        top: 27px
                        background: var(--dim-gray-dark)
                        opacity: .95
                        padding: 8px 12px
                        box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px
                        border-radius: 10px
                        cursor: pointer
                        .arrow
                            width: 10px
                            height: 10px
                            background: var(--dim-gray-dark)
                            transform: rotateZ(45deg)
                            opacity: .95
                            position: absolute
                            z-index: 1
                            left: 12px
                            top: -5px
                        .rating-single
                            display: flex
                            justify-content: space-between
                            span
                                white-space: pre
                                position: relative
                                z-index: 2
                                padding: 2px 0
                                display: block
                                font-size: 12px
                                font-weight: 500
                                text-transform: none
                                color: white
                                &:last-child
                                    margin-left: 20px
                    .isLastRating
                        top: auto
                        bottom: 27px
                        .arrow
                            top: auto
                            bottom: -5px
                ion-icon
                    color: var(--indigo-medium)
                    font-size: 14px
                    margin-right: 3px
                .rating-score
                    color: var(--indigo-medium)
                    font-weight: 700
                    font-size: 13px
                    margin-right: 6px
                .date
                    font-size: 13px
                    color: var(--silver-dark)
        .recommend
            min-width: 26px
            height: 25px
            padding-left: 1px
            background: var(--indigo-medium)
            border-radius: 50%
            display: flex
            justify-content: center
            align-items: center
            img
                display: block
                width: 15px
                height: 13px
    .comment
        font-size: 13px
        line-height: 20px
        white-space: pre-line
    .border-review
        width: 36px
        height: 1px
        margin: auto
        background: var(--silver-light)
        margin-top: 20px

.review-unverified
    .unverified
        display: inline-block
    .top
        .details
            .title
                color: var(--dim-gray-light)
        .recommend
            display: none
    .comment
        color: var(--dim-gray-light)
</style>

<script>
import { IonIcon } from '@ionic/vue'
import { star } from 'ionicons/icons'

import moment from "moment"
moment.locale('de-ch')

export default {
    props: ['review', 'isLastRating', 'showSeparator'],
    components: {IonIcon},
    setup() {
        return { star }
    },
    data() {
        return {
            showRatingDropdown: false
        }
    },
    methods: {
        closeRatingDropdown() {
            this.showRatingDropdown = false
        },
        formattedDate(review) {
            return moment(review && review.createdOn && review.createdOn.seconds && review.createdOn.seconds*1000).format('MMM YYYY')
        },
        averageRatingPerReview(review) {
            return Math.round((review && review.categories && review.categories && review.categories.reduce((a, b) => a + b.rating || 0, 0)/review.categories.length) * 10) / 10
        },
    }
}
</script>