import { workersCollection, competencesCollection, auth } from '@/firebase'

export default {
    computed: {
      user() {
        return this.$store.state.user
      },
      competences() {
        return this.$store.state.competences
      },
      workers() {
        return this.$store.state.workers
      }
    },
    methods: {
        async getUser() {
          if(!(this.user && this.user.phone)) {
            return
          }
          
          const { docs: [ doc ] } = await workersCollection.where('phone', '==', this.user && this.user.phone).get()
          const token = await auth.currentUser.getIdToken()
          let userData

          if(doc) {
            userData = { id: doc.id, token, ...doc.data() }
          }
    
          if(userData) {
            this.$store.commit('setUser', userData)
          } else {
            this.$store.commit('setUser', null)
            this.$router.push({name: 'Login'})
          }
        },
        async getCompetences() {
          const snapshot = await competencesCollection.get()
          const competences = {}
          snapshot.docs.forEach(doc => {
            competences[doc.id] = {...doc.data()}
          })
          this.$store.commit('setCompetences', competences)
        },
        async getWorkers() {
          const snapshot = await workersCollection.get()
          const workers = {}
          snapshot.docs.forEach(doc => {
            workers[doc.id] = {id: doc.id, ...doc.data()}
          })
          this.$store.commit('setWorkers', workers)
        },
    }
}