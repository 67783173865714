<template>
  <ion-page>
      <ion-content>
          <ion-spinner v-if="!loaded"></ion-spinner>
          <div class="withdraw-header" v-if="loaded && step === 1">
            <div class="withdraw-header-action" @click="$router.back()">
              <ion-icon :icon="closeOutline"/>
            </div>
            <div class="content">
              <span>Bonus auszahlen</span>
            </div>
            <span v-if="step === 1" class="next" :class="{disabled: pendingRequest || myBalance === 0 || amount == 0 || !amount}" @click="goToNextStep">Auszahlen</span>
          </div>
          <div class="withdraw" v-if="loaded && step === 1">
            <div class="message message-yellow" v-if="myBalance === 0 && !pendingRequest">
              <div class="icon-wrapper">
                <ion-icon :icon="informationCircleOutline"/>
              </div>
              <span>Du hast derzeit <strong>kein Bonus-Guthaben</strong>. Teile Projektreferenzen und sammle Bewertungen, um so bei guter Arbeit Bonuszahlungen zu erhalten.</span>
            </div>
            <div class="message message-yellow" v-if="pendingRequest">
              <div class="icon-wrapper">
                <ion-icon :icon="informationCircleOutline"/>
              </div>
              <span>Du hast am <strong>{{pendingDate}}</strong> bereits eine Auszahlung von <strong>{{pendingRequest.amount}} CHF</strong> angefordert. Sobald diese bearbeitet worden ist, kannst du eine erneute Auszahlung anfordern.</span>
            </div>
            <div class="form-field">
              <label>BETRAG</label>
              <div class="input-wrapper">
                <input v-model="amount" min="0" inputmode="numeric" pattern="[0-9]*" oninput="validity.valid||(value='');" placeholder="0">
                <span>CHF</span>
              </div>
              <small>Maximal {{myBalance}} CHF</small>
            </div>
            <div class="form-field">
              <label>KOMMENTAR</label>
              <input v-model="comment" placeholder="Kommentar">
            </div>
            <div class="message" v-if="myBalance !== 0">
              <div class="icon-wrapper">
                <ion-icon :icon="informationCircleOutline"/>
              </div>
              <span>Die Auszahlung wird innert Kürze via <strong>TWINT</strong> getätigt.</span>
            </div>
        </div>
        <div class="withdraw-final" v-if="loaded && step === 2">
          <img src="@/assets/money-bag.svg">
          <h4>Auszahlungsanforderung versendet</h4>
          <p>
            Wir haben deine Auszahlungsanforderungen von <strong>{{amount}} CHF</strong> an unsere Administration versendet. Du wirst informiert, sobald die Zahlung ausgeführt worden ist.
          </p>
          <ion-button @click="withdraw">Fertig</ion-button>
        </div>
      </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonSpinner,
  IonIcon, 
  IonButton,
} from '@ionic/vue'

import { closeOutline, informationCircleOutline } from 'ionicons/icons'
import { workersCollection } from '@/firebase'
import toast from '@/mixins/toast'
import loader from '@/mixins/loader'
import moment from "moment"
moment.locale('de-ch')

export default {
  mixins: [toast, loader],
  components: {
    IonPage,
    IonContent,
    IonSpinner,
    IonIcon, 
    IonButton,
  },
  data() {
    return {
      step: 1,
      amount: null,
      comment: '',
      myBalance: 0,
      loaded: false
    }
  },
  async mounted() {
      this.resetAndGetData()
  },
  computed: {
      user() {
          return this.$store.state.user
      },
      pendingDate() {
        return moment(this.pendingRequest && this.pendingRequest.requestedOn.seconds*1000).format('DD. MMMM')
      }
  },
  watch: {
    $route(r) {
      if(r.name === 'Withdraw') {
        this.resetAndGetData()
      } else {
        this.$store.commit('setHideTabs', false)
      }
    },
    step(v) {
      if(v === 2) {
        this.$store.commit('setHideTabs', true)
      } else {
        this.$store.commit('setHideTabs', false)
      }
    }
  },
  methods: {
    async resetAndGetData() {
      this.step = 1
      this.amount = null
      this.comment = ''
      this.loaded = false
      await this.checkIfHasPendingWithdrawalRequests()
      await this.getMyBalance()
      this.loaded = true
    },
    goToNextStep() {
      if(this.pendingRequest || this.myBalance === 0 || this.amount == 0 || !this.amount) {
        return
      }

      if(this.amount > this.myBalance) {
        this.openToast('Nicht genügend Bonus-Guthaben. Du kannst maximal '+this.myBalance+' CHF auszahlen.', 7000)
        return
      }

      this.step = 2
    },
    async withdraw() {
      await this.startLoader()

      // Store sub-collection to db
      await workersCollection.doc(this.user && this.user.id).collection('withdrawalRequests').add({
        requestedOn: new Date(),
        amount: this.amount,
        comment: this.comment,
        pending: true,
      })

      let segment = {}
       if(this.user && this.user.segment) {
         try {
            segment = JSON.parse(this.user.segment)
         } catch (e) {
            console.log(e)
         }
      }

      window.analytics.track('Withdrawal Request Sent', {
        name: this.user && this.user.firstname + ' ' + this.user.lastname,
        jobtitle: this.user && this.user.jobtitle,
        ...segment,
        amount: this.amount,
      })

      this.$router.push({name: 'Dashboard'})

      await this.stopLoader()
    },
    async checkIfHasPendingWithdrawalRequests() {
        const snapshot = await workersCollection.doc(this.user && this.user.id).collection('withdrawalRequests').where("pending", "==", true).get()
        if(snapshot.docs && snapshot.docs.length) {
          const pendingRequest = snapshot.docs[0].data()
          this.pendingRequest = pendingRequest
        }
    },
    async getMyBalance() {
        const snapshot = await workersCollection.doc(this.user && this.user.id).collection('transactions').get()
        const myTransactions = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        this.myBalance = myTransactions.reduce((a, b) => b.type === 'add' || !b.type ? a + parseInt(b.amount) : a - parseInt(b.amount), 0)
    },
  },
  setup() {
    return { closeOutline, informationCircleOutline }
  },
}
</script>

<style lang="sass" scoped>

.withdraw-header
  display: flex
  justify-content: space-between
  padding: 52px 23px 25px
  background: var(--dim-gray-dark)
  color: white
  text-align: center
  .withdraw-header-action
    width: 40px
    display: flex
    ion-icon
      cursor: pointer
      font-size: 22px
      font-weight: 700
  .content
    width: 140px
    margin: auto
    position: absolute
    right: 0
    left: 0
    span
      display: block
      font-size: 18px
      font-weight: 700
      line-height: 21px
      margin-bottom: 2px
  .next
    cursor: pointer
    white-space: nowrap
    margin-top: 2px
    font-size: 14px
    text-align: right
    line-height: 16px
    font-weight: 700
    width: 70px
    position: absolute
    right: 15px
  .disabled
    cursor: pointer
    color: var(--dim-gray-light)

.withdraw
  padding: 16px 0
  .form-field
    display: flex
    flex-direction: column
    padding: 16px 31px
    .input-wrapper
      display: flex
      position: relative
      input
        padding-right: 47px
      span
        position: absolute
        top: 0
        bottom: 0
        right: 5px
        margin: auto
        height: 20px
        font-size: 15px
    small
      display: block
      color: var(--silver-dark)
      font-size: 14px
      line-height: 16px
      margin-top: 8px
    label
      text-transform: uppercase
      font-size: 12px
      line-height: 14px
      font-weight: 700
      margin-bottom: 4px
      color: var(--dim-gray-light)
    input
      outline: 0
      border: 0
      border-bottom: 1px solid #E9EAEC
      font-size: 15px
      padding: 10px 0
      color: var(--dim-gray-dark)
      width: 100%
      -webkit-appearance: none
      -moz-appearance: textfield
      &::-webkit-inner-spin-button, &::-webkit-outer-spin-button
        -webkit-appearance: none
        margin: 0
      &::placeholder
        color: var(--dim-gray-dark)
  .message
    background: var(--indigo-lighter)
    margin: 8px 31px
    padding: 16px
    display: flex
    .icon-wrapper
      width: 26px
      margin-right: 12px
      ion-icon
        font-size: 26px
        color: var(--indigo-dark)
    span
      color: var(--indigo-dark)
      font-size: 12px
      line-height: 20px
  .message-yellow
    background: var(--yellow-light)
    *
      color: var(--dim-gray-dark) !important

.withdraw-final
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  text-align: center
  height: calc(100vh - 80px)
  padding: 40px
  box-sizing: border-box
  @media (orientation: landscape)
    margin: 50px 0
  h4, p
    color: var(--dim-gray)
  h4
    font-size: 22px
    line-height: 30px
    font-weight: 500
  p
    font-size: 14px
    font-weight: 400
    line-height: 20px

ion-spinner
  color: var(--indigo-dark)
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  margin: auto
  display: block
  transform: scale(0.85)
</style>