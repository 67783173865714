<template>
    <div class="enter-verification-code-form">
        <div class="verification-code-input">
          <input
            v-for="index in 6"
            type="number"
            v-model="digits[index - 1]"
            :tabindex="index"
            :key="index"
            :autocomplete="index === 1 ? 'one-time-code' : 'off'"
            :ref="`digit-${index - 1}`"
            @keyup="handleBackspace($event, index)"
            @paste.prevent="onPaste"
            @input="onInput($event, index - 1)"
          />
        </div>
        <ion-button @click="verify">Anmelden</ion-button>
    </div>
</template>

<style lang="sass" scoped>
.enter-verification-code-form
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  .verification-code-input
    display: flex
    margin-bottom: 20px
    input
      width: 40px
      margin: 0 5px
      padding: 5px 10px 5px 12px
      font-size: 22px
      border: 1px solid #dbdbdb
      border-radius: 10px !important
      outline: 0
      -webkit-appearance: none
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button
      -webkit-appearance: none
      margin: 0
    input[type="number"]
      -moz-appearance: textfield
  ion-button
    width: 300px
    margin-bottom: 10px
</style>

<script>
import { workersCollection } from '@/firebase'
import toast from '@/mixins/toast'
import loader from '@/mixins/loader'
import activities from '@/mixins/activities'
import { IonButton } from '@ionic/vue'

export default {
  props: ['phone', 'step'],
  mixins: [toast, loader, activities],
  components: { IonButton },
  data() {
    return {
      digits: [],
    }
  },
  watch: {
    step(v) {
      if(v === 1) {
        setTimeout(() => {
          this.$refs["digit-0"].focus();
        }, 1000)
      }
    }
  },
  methods: {
    handleBackspace(e, index) {
      if (e.key == "Backspace") {
        this.$refs[`digit-${index === 1 ? index - 1 : index - 2}`].focus()
        return
      }
    },
    onInput(e, index) {
      if (e.inputType !== "deleteContentBackward") {
        if (
          !this.isNumber(e.target.value) || e.target.value.length > 1
        ) {
          this.digits[index] = e.data
        } else if (this.isNumber(e.target.value)) {
          this.digits[index] = e.target.value
          if(index >= 5) this.verify()
        }

        if (index < 5) this.$refs[`digit-${index + 1}`].focus()
      }
    },
    splitAndSet(value) {
      const splitValue = value.split("");
      if (this.isNumber(splitValue)) {
        splitValue.forEach((value, index) => {
          this.digits[index] = value
        });
      }
    },
    onPaste(e) {
      this.splitAndSet(e.clipboardData.getData("text").trim());
    },
    isNumber(value) {
      var regex = new RegExp("^[0-9]+$");
      return regex.test(parseInt(value));
    },
    verify() {
      if(this.digits.length !== 6) {
        this.$refs[`digit-${this.digits.length}`].focus()
        return
      }

      const code = this.digits.join('')

      if(!code) {
        this.openToast('Der eingegebene Verifizierungscode ist ungültig. Bitte versuche es erneut.', 5000)
        return
      }

      this.startLoader()
      window.confirmationResult.confirm(code).then(async result => {
        // Set user to state
        const user = result.user;
        const token = await result.user.getIdToken()

        if(user) {
          const { docs: [ doc ] } = await workersCollection.where('phone', '==', user.phoneNumber).get()
          const userData = { id: doc.id, token, ...doc.data() }

          if(userData) {
            this.$store.commit('setUser', userData)
            window.analytics.track('Login Step 3 Successful')

            let segment = {}
            if(userData && userData.segment) {
              try {
                  segment = JSON.parse(userData.segment)
              } catch (e) {
                  console.log(e)
              }
            }

            // Analytics identify
            window.analytics.identify(userData.id, {
              name: userData && userData.firstname + ' ' + userData.lastname,
              jobtitle: userData && userData.jobtitle,
              ...segment
            })

            let firstLogin = await workersCollection.doc(doc.id).collection('activities').where('type', '==', 'login').get()
            firstLogin = !firstLogin.docs.length
            if(firstLogin) {
              await this.addActivity({
                type: 'login',
                createdOn: new Date(),
              })
            }

            this.$router.push({name: 'Dashboard'})
          }
          
          // Reset values
          setTimeout(() => {
            this.$emit('setStep', 0)
            this.digits = []
            this.stopLoader()
          }, 500)
        }

      }).catch(error => {
        console.log(error)
        this.stopLoader()
        this.openToast('Der eingegebene Verifizierungscode ist ungültig. Bitte versuche es erneut.', 5000)
        setTimeout(() => {
          this.$refs[`digit-5`].focus()
        }, 500)
      });
    }
  },
}
</script>