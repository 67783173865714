<template>
    <div class="bonusBox">
        <div class="content">
            <h3>{{myBalance}} CHF</h3>
            <small>Aktueller Kontostand deiner Bonuszahlungen</small>
        </div>
        <ion-button @click="$router.push({name: 'Withdraw'})">Auszahlen</ion-button>
    </div>
</template>

<script>
import { IonButton } from '@ionic/vue'

export default {
    props: ['myBalance'],
    components: { IonButton },
}
</script>

<style lang="sass" scoped>
.bonusBox
    padding: 40px 26px 24px
    margin: 0 5vw
    display: flex
    align-items: center
    justify-content: space-between
    background: var(--indigo-medium)
    color: white
    border-radius: 5px
    .content
        text-align: center
        h3
            margin-top: 0
            margin-bottom: 8px
            font-size: 22px
            font-weight: 600
        small
            max-width: 150px
            display: inline-block
            line-height: 20px
            font-size: 14px
            font-weight: 500
            @media (max-width: 350px)
                font-size: 12px
                margin: 0 10px
    ion-button
        min-width: 0 !important
        --background: var(--indigo-light)
        --box-shadow: none
</style>