import { workersCollection } from '@/firebase'

export default {
    computed: {
        user() {
            return this.$store.state.user
        },
        activities() {
            return this.$store.state.activities
        },
        hasNewActivities() {
            if(this.$route.name !== 'Activities' && this.user && this.user.viewedActivitiesOn && this.user.viewedActivitiesOn.seconds && this.activities.length && this.activities[0].createdOn && this.activities[0].createdOn.seconds && this.activities[0].createdOn.seconds*1000 > this.user.viewedActivitiesOn.seconds*1000) {
                return true
            }
            return false
        }
    },
    methods: {
        async addActivity(activity, userId) {
            await workersCollection
            .doc(userId || (this.user && this.user.id))
            .collection('activities')
            .add(activity)
        },
        async updateViewedActiviesOn() {
            if(this.user) {
                await workersCollection.doc(this.user.id).update({
                    viewedActivitiesOn: new Date(this.activities.length && this.activities[0].createdOn.seconds*1000)
                })

                this.$store.commit('setUser', {...this.user, viewedActivitiesOn: {seconds: Math.round((new Date(this.activities.length && this.activities[0].createdOn.seconds*1000).getTime())/1000)}})
            }
        },
    }
}