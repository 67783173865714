<template>
    <transition name="fade">
        <div class="reference-fullscreen" v-if="showFullscreen">
            <div class="reference-fullscreen-header">
                <ion-icon :icon="closeOutline" @click="closeFullscreen"></ion-icon>
                <span v-if="!hideCounter">{{fullscreenIndex+1}}/{{fullscreenPhotos && fullscreenPhotos.length}}</span>
            </div>
            <div class="reference-current-photo">
                <div class="arrows">
                    <div class="previous ion-activatable ripple-parent" @click="goToPreviousSlide" v-if="fullscreenIndex > 0">
                        <ion-icon :icon="chevronBackOutline" />
                        <ion-ripple-effect />
                    </div>
                    <div class="next ion-activatable ripple-parent" @click="goToNextSlide" v-if="fullscreenPhotos && fullscreenIndex < fullscreenPhotos.length-1">
                        <ion-icon :icon="chevronForwardOutline" />
                        <ion-ripple-effect />
                    </div>
                </div>
                <ion-slides
                class="images-wrapper"
                v-if="fullscreenPhotos && fullscreenPhotos.length"
                :pager="false"
                :options="{
                initialSlide: fullscreenIndex,
                speed: 400,
                }"
                @ionSlidePrevStart="setFullscreenSlide(fullscreenIndex-1)"
                @ionSlideNextStart="setFullscreenSlide(fullscreenIndex+1)"
                ref="fullscreenSlides"
            >
                <ion-slide v-for="refPhoto in fullscreenPhotos" :key="refPhoto">
                    <img :src="refPhoto" />
                </ion-slide>
            </ion-slides>
            </div>
            <div class="reference-fullscreen-footer"></div>
        </div>
    </transition>
</template>

<script>
import { closeOutline, chevronBackOutline, chevronForwardOutline } from 'ionicons/icons'
import {   
  IonIcon,
} from '@ionic/vue'

export default {
    components: {IonIcon},
    setup() {
        return {
            closeOutline,
            chevronBackOutline,
            chevronForwardOutline
        }
    },
    computed: {
        hideCounter() {
            return this.$store.state.fullscreenWithoutCounter
        },
        fullscreenIndex() {
            return this.$store.state.fullscreenIndex
        },
        fullscreenPhotos() {
            return this.$store.state.fullscreenPhotos
        },
        showFullscreen() {
            return this.$store.state.showFullscreen
        },
        user() {
            return this.$store.state.user
        }
    },
    methods: {
        goToPreviousSlide() {
            this.$store.commit('setFullscreen', {index: this.setFullscreenIndex(this.fullscreenIndex - 1)})
        },
        goToNextSlide() {
            this.$store.commit('setFullscreen', {index: this.setFullscreenIndex(this.fullscreenIndex + 1)})
        },
        setFullscreenIndex(i) {
            let fullscreen = this.$refs.fullscreenSlides
            if(!fullscreen.slideTo) {
                fullscreen = fullscreen.$el
            }

            fullscreen.slideTo(i)
            return i
        },
        closeFullscreen() {
            this.$store.commit('hideFullscreen')
        },
        async setFullscreenSlide() {
            let fullscreen = this.$refs.fullscreenSlides
            if(!fullscreen.slideTo) {
                fullscreen = fullscreen.$el
            }

            const i = await fullscreen.getActiveIndex()
            console.log(i)
            this.$store.commit('setFullscreen', {index: i})
        },
    },
    watch: {
        showFullscreen(v) {
            if(this.$route.name !== 'ReferenceComments') {
              this.$store.commit('setHideTabs', v)
            }

            if(v) {
                let segment = {}
                if(this.user && this.user.segment) {
                    try {
                        segment = JSON.parse(this.user.segment)
                    } catch (e) {
                        console.log(e)
                    }
                }

                window.analytics.track('Reference Photo Opened', {
                    name: this.user && this.user.firstname + ' ' + this.user.lastname,
                    jobtitle: this.user && this.user.jobtitle,
                    ...segment
                })
            }
        }
    },
}
</script>

<style lang="sass" scoped>
.reference-fullscreen
    position: -webkit-fixed
    position: fixed
    top: 0
    left: 0
    transform: translate3d(0,0,0)
    width: 100vw
    height: 100vh
    z-index: 10000
    .reference-fullscreen-header, .reference-fullscreen-footer
        width: 100%
        height: 50px
        background: black
    .reference-fullscreen-footer
        height: 100px
    .reference-fullscreen-header
        position: relative
        display: flex
        padding: 15px 20px
        width: 100vw
        top: 0
        left: 0
        z-index: 200
        ion-icon
            color: white
            font-size: 20px
            cursor: pointer
        span
            color: #fff
            text-align: center
            position: absolute
            left: 0
            right: 0
            top: 0
            bottom: 0
            margin: auto
            width: 100px
            height: 15px
            font-size: 13px
    .reference-current-photo
        display: flex
        height: calc(100vh - 150px)
        justify-content: center
        align-items: center
        background: black
        position: relative
        .images-wrapper
            width: 100%
            height: 100%
            overflow: hidden
            position: relative
            .images
                display: flex
                align-items: center
                position: absolute
                height: 100%
                transition: .25s ease
                .img-wrapper
                    width: 100vw
                    height: 100%
                    display: flex
                    justify-content: center
                    align-items: center
                    img
                        max-width: 100%
                        max-height: 100%
        .arrows
            position: absolute
            top: 0
            bottom: 0
            left: 0
            right: 0
            width: 100vw
            height: 50px
            display: flex
            justify-content: space-between
            margin: auto
            .previous, .next
                cursor: pointer
                min-width: 0 !important
                width: 34px
                height: 45px
                overflow: hidden
                background: white
                position: absolute
                z-index: 200
                display: flex
                justify-content: center
                align-items: center
                ion-icon
                    font-size: 20px
                    --ionicon-stroke-width: 50px
                    color: var(--dim-gray-dark)
            .previous
                left: -5px
                border-radius: 0
                border-top-right-radius: 50%
                border-bottom-right-radius: 50%
                padding-right: 3px
            .next
                right: -5px
                border-radius: 0
                border-top-left-radius: 50%
                border-bottom-left-radius: 50%
                padding-left: 3px
</style>