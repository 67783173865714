<template>
  <ion-page>
    <ion-content>
        <ion-spinner v-if="loadingScreen"></ion-spinner>
        <div class="give-review" v-if="!loadingScreen && !isSent && !isInvalid">
          <div class="profile-image" v-if="photo">
            <div class="img-wrapper" :style="{background: 'url('+photo+')', backgroundSize: 'cover', backgroundPosition: 'center'}">
            </div>
          </div>
          <div class="profile-image" v-else>
            <span>{{userInitials}}</span>
          </div>
          <h2>Bewerten Sie die Arbeit von {{userName}}</h2>
          <div class="form-field">
            <label>NAME</label>
            <span class="required-field" v-if="emptyRequiredField(name)">Muss ausgefüllt sein</span>
            <input v-model="name" placeholder="Name" :class="{emptyRequiredField: emptyRequiredField(name)}">
          </div>
          <div class="form-field">
            <label>TELEFONNUMMER</label>
            <span class="required-field" v-if="emptyRequiredField(phoneNumber)">Muss ausgefüllt sein</span>
            <div class="phoneInput" :class="{emptyRequiredField: emptyRequiredField(phoneNumber)}">
              <select v-model="countryCode">
                <option v-if="countryCode !== '+41' && countryCode !== '+46'" :value="countryCode">{{countryCode}}</option>
                <option value="+41">+41</option>
                <option value="+46">+46</option>
              </select>
              <input v-model="phoneNumber" placeholder="Telefonnummer (Mobile)" min="0" inputmode="numeric" pattern="[0-9]*">
            </div>
          </div>
          <div class="form-field">
            <label>UNTERNEHMEN</label>
            <span class="required-field" v-if="emptyRequiredField(company)">Muss ausgefüllt sein</span>
            <input v-model="company" placeholder="Unternehmen" :class="{emptyRequiredField: emptyRequiredField(company)}">
          </div>
          <div class="form-field">
            <label>PROJEKTNAME</label>
            <span class="required-field" v-if="emptyRequiredField(project)">Muss ausgefüllt sein</span>
            <input v-model="project" placeholder="Projektname" :class="{emptyRequiredField: emptyRequiredField(project)}">
          </div>
          <div class="form-field">
            <label>KOMMENTAR</label>
            <span class="required-field" v-if="emptyRequiredField(comment)">Muss ausgefüllt sein</span>
            <autoexpand-textarea v-model:value="comment" placeholder="Kommentar" :emptyRequiredField="emptyRequiredField(comment)"/>
          </div>
          <div class="categories">
            <div class="category" v-for="(c, index) in categoriesRating" :key="index">
              <span>{{c.name || 'Sauberkeit Ausführung'}}</span>
              <div class="stars" :class="{'starRed': requiredFieldsEmpty && c.rating === 0}">
                <ion-icon v-for="i in 5" :key="i+'-star'" :icon="c.rating > i-1 ? star : starOutline" @click="categoriesRating[index].rating = i; requiredFieldsEmpty = false"/>
              </div>
            </div>
          </div>
          <span class="required-reviews" v-if="hasEmptyCategory && requiredFieldsEmpty">Bewertungen müssen ausgefüllt sein</span>
          <div class="would-recommend">
            <ion-checkbox v-model="recommend"></ion-checkbox>
            <span @click="recommend = !recommend">Ich würde {{userName}} weiterempfehlen.</span>
          </div>
          <ion-button @click="submitForm" expand="block">Fertig</ion-button>
      </div>
      <div class="is-sent" v-if="!loadingScreen && isSent">
        <img src="@/assets/text-msg.svg" alt="">
        <h3>Fast geschafft...</h3>
        <p>
          Um die Echtheit Ihrer Bewertung zu bestätigen, haben wir Ihnen auf <strong>{{phoneNumberWithCountryCode}}</strong> eine Nachricht mit einem Bestätigungslink gesendet. Bitte rufen Sie diesen auf.
        </p>
        <span @click="resendSMS" v-if="isSent">Bestätigungslink erneut senden</span>
      </div>
      <div class="is-invalid" v-if="!loadingScreen && isInvalid">
        <img src="@/assets/is-expired.svg" alt="">
        <h3>Bewertungslink ungültig oder bereits verwendet</h3>
        <p>Dieser Bewertungslink ist ungültig oder wurde bereits verwendet. Fordern Sie von der zu bewertenden Person einen neuen Link an, um  eine Bewertung abzugeben.</p>      
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonIcon, IonCheckbox, IonSpinner, IonButton } from '@ionic/vue'
import { starOutline, starHalfOutline, star } from 'ionicons/icons'
import toast from '@/mixins/toast'
import loader from '@/mixins/loader'
import AutoexpandTextarea from '@/components/general/autoexpandTextarea'
import { getFormData, submitFormData } from '@/services/publicReviewForm'
import pn from 'awesome-phonenumber'

export default {
  components: { IonPage, IonContent, IonIcon, IonCheckbox, AutoexpandTextarea, IonSpinner, IonButton },
  mixins: [toast, loader],
  data() {
    return {
      name: '',
      countryCode: '+41',
      phoneNumber: '',
      company: '',
      project: '',
      comment: '',
      recommend: false,
      loadingScreen: true,
      user: null,
      categoriesRating: [],
      isInvalid: false,
      isSent: false,
      requiredFieldsEmpty: false
    }
  },
  setup() {
    return { starOutline, starHalfOutline, star }
  },
  async mounted() {
      if(this.$route.query.phone) {
        this.countryCode = this.$route.query.phone ? '+'+pn(decodeURIComponent(this.$route.query.phone)).getCountryCode() : '+41'
        this.phoneNumber = this.$route.query.phone ? parseInt(decodeURIComponent(this.$route.query.phone).replace(this.countryCode, '')) : ''
      }

      if(this.$route.query.project) {
        this.project = decodeURIComponent(this.$route.query.project)
      }

      if(this.$route.query.name) {
        this.name = decodeURIComponent(this.$route.query.name)
      }

      this.getData()
  },
  computed: {
    phoneNumberWithCountryCode() {
      return this.countryCode.toString()+(this.phoneNumber.toString().replace(/\s/g,''))
    },
    userInitials() {
        return this.user && this.user.firstname && this.user.lastname && (this.user.firstname[0]+this.user.lastname[0]) || 'FM'
    },
    photo() {
        return this.user && this.user.photo
    },
    userName() {
        return this.user && this.user.firstname + ' ' + this.user.lastname
    },
    hasEmptyCategory() {
      return this.categoriesRating.find(r => r.rating === 0)
    },
  },
  watch: {
    name() {
      this.requiredFieldsEmpty = false
    },
    company() {
      this.requiredFieldsEmpty = false
    },
    project() {
      this.requiredFieldsEmpty = false
    },
    phoneNumber() {
      this.requiredFieldsEmpty = false
    },
    comment() {
      this.requiredFieldsEmpty = false
    },
  },
  methods: {
    emptyRequiredField(val) {
      if(!val && this.requiredFieldsEmpty) {
        return true
      } else {
        return false
      }
    },
    async getData() {
      try {
        const form = await getFormData(this.$route.params.id)
        const formData = await form.json()
        if(formData.completed) {
          this.isInvalid = true
        } else {
          this.user = formData
          this.categoriesRating = formData.categories && formData.categories.map(c => ({id: c.id, name: c.name, rating: 0}))
        }
      } catch (e) {
        console.log(e)
        this.isInvalid = true
      }
      this.loadingScreen = false
    },
    async resendSMS() {
      this.startLoader()
      await submitFormData({fullname: this.name, phoneNumber: this.phoneNumberWithCountryCode}, this.$route.params.id)
      this.stopLoader()
    },
    async submitForm() {

      if(!this.name || !this.phoneNumber || !this.company || !this.project || !this.comment || this.hasEmptyCategory) {
        this.openToast('Bitte füllen Sie alle erforderlichen Felder aus.', 6000)
        this.requiredFieldsEmpty = true
        return
      }

      if(!pn(this.phoneNumberWithCountryCode.toString()).isValid()) {
        this.openToast('Diese Telefonnummer hat ein ungültiges Format.', 6000)
        return
      }

      this.startLoader()

      const form = await submitFormData({categories: this.categoriesRating, comment: this.comment, company: this.company, project: this.project, recommend: this.recommend, fullname: this.name, phoneNumber: this.phoneNumberWithCountryCode}, this.$route.params.id)
      
      if(form.status === 200) {
        this.isSent = true

        window.analytics.track('Review Submitted')
      } else if(form.status === 403) {
        this.openToast('Sie haben die Telefonnummer des Arbeitnehmers eingegeben.', 6000)
      }else {
        this.openToast('Es ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Kundendienst.', 6000)
      }

      this.stopLoader()
    }
  }
}
</script>

<style lang="sass" scoped>
.emptyRequiredField
  border-bottom: 1px solid var(--indian-red) !important

.give-review
  padding: 16px 0 50px
  .profile-image
    width: 100px
    height: 100px
    margin: 30px auto 15px
    position: relative
    display: flex
    justify-content: center
    align-items: center
    background: var(--indigo-lighter)
    border-radius: 50%
    .img-wrapper
      width: 100%
      height: 100%
      overflow: hidden
      border-radius: 50%
      img
        object-fit: cover
        min-width: 100%
        min-height: 100%
    span
        position: absolute
        top: -2px
        right: 0
        left: 0
        bottom: 0
        margin: auto
        text-align: center
        height: 30%
        font-size: 28px
        color: var(--indigo-medium)
        letter-spacing: 2px
  h2
    font-size: 22px
    font-weight: 500
    line-height: 30px
    text-align: center
    margin: 0 40px 14px
    color: var(--dim-gray-dark)
  .form-field
    display: flex
    flex-direction: column
    padding: 12px 25px
    position: relative
    .required-field
      position: absolute
      top: 10px
      right: 30px
      font-size: 12px
      font-weight: 400
      line-height: 14px
      text-align: right
      color: var(--indian-red)
    .phoneInput
      display: flex
      select
        background: transparent
        padding: 0 5px
        outline: 0
        border: 1px solid white
        border-bottom: 1px solid #E9EAEC
        color: var(--dim-gray-dark)
      input
        padding-left: 7px
    label
      text-transform: uppercase
      font-size: 12px
      line-height: 14px
      font-weight: 700
      margin-bottom: 4px
      color: var(--dim-gray-light)
    input, textarea
      resize: none
      outline: 0
      border: 0
      border-bottom: 1px solid #E9EAEC
      font-size: 15px
      padding: 10px 0
      color: var(--dim-gray-dark)
      &::placeholder
        color: var(--dim-gray-dark)
    input
      width: 100%
      -webkit-appearance: none
      -moz-appearance: textfield
      &::-webkit-inner-spin-button, &::-webkit-outer-spin-button
        -webkit-appearance: none
        margin: 0
  .categories
    padding: 12px 25px 0
    margin: 10px 0
    .category
      display: flex
      justify-content: space-between
      flex-wrap: wrap
      margin-bottom: 25px
      span
        font-size: 14px
        font-weight: 700
        line-height: 16px
        text-align: left
        color: var(--dim-gray-dark)
        margin-bottom: 5px
      ion-icon
        font-size: 20px
        margin-left: 4px
        color: var(--dim-gray-medium)
        cursor: pointer
      .starRed
        ion-icon
          color: var(--indian-red)
      @media (max-width: 340px)
        span
          font-size: 12px
        ion-icon
          margin-left: 1px
  .required-reviews
    font-size: 12px
    font-weight: 400
    line-height: 14px
    color: var(--indian-red)
    display: block
    margin: -10px 25px 30px
  .would-recommend
    padding: 0 25px 12px
    margin-top: -5px
    margin-bottom: 15px
    display: flex
    align-items: center
    span
      margin-left: 10px
      color: var(--dim-gray-dark)
      font-size: 14px
      font-weight: 400
      line-height: 15px
  ion-button 
    margin: 10px 24px

.is-sent, .is-invalid
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  height: calc(100vh - 60px)
  margin: 0
  img
    width: 65px
    height: auto
  h3, p
    text-align: center
  h3
    margin: 29px 40px 7px
    font-size: 22px
    line-height: 24px
    font-weight: 700
    color: var(--dim-gray-dark)
  p
    margin: 15px 30px 25px
    font-size: 14px
    font-weight: 400
    line-height: 20px
    color: var(--dim-gray-dark)
  span
    font-size: 14px
    color: var(--dim-gray-light)
    text-decoration: underline
    cursor: pointer

ion-spinner
  color: var(--indigo-dark)
  margin: 47vh auto 0
  display: block
  transform: scale(0.85)
</style>