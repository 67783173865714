import { workersCollection } from '@/firebase'
import { star, starHalfOutline, starOutline } from 'ionicons/icons'

export default {
    data() {
        return {
            myReviews: []
        }
    },
    setup() {
        return { star, starHalfOutline, starOutline }
    },
    methods: {
      async getMyReviews() {
        const snapshot = await workersCollection.doc(this.$route.params.workerId || this.user && this.user.id).collection('reviews').orderBy('createdOn', 'desc').get()
        const myReviews = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        this.myReviews = [...myReviews.filter(r => r.verified || !this.$route.params.workerId)]
      },
      starConfig(s) {
        if(s === 1) {
            if(this.overallRating < 0.5) return starOutline
            else if(this.overallRating >= 1) return star
            else return starHalfOutline
        } else if(s === 2) {
            if(this.overallRating >= 2) return star
            else if(this.overallRating < 2 && this.overallRating > 1) return starHalfOutline
            else return starOutline
        } else if(s === 3) {
            if(this.overallRating >= 3) return star
            else if(this.overallRating < 3 && this.overallRating > 2) return starHalfOutline
            else return starOutline      
        } else if(s === 4) {
            if(this.overallRating >= 4) return star
            else if(this.overallRating < 4 && this.overallRating > 3) return starHalfOutline
            else return starOutline       
        } else {
            if(this.overallRating >= 4.9) return star
            else if(this.overallRating < 4.9 && this.overallRating > 4) return starHalfOutline
            else return starOutline    
        }
      },
    },
    computed: {
      user() {
          return this.$store.state.user
      },
      averageRatings() {
        const avgRatings = []
        this.myReviews && this.myReviews.length && this.myReviews.filter(r => r.verified).forEach(r => {
            r.categories.forEach(c => {
                if(!avgRatings.find(cat => cat.name === c.name)) {
                    avgRatings.push({id: c.id, name: c.name, ratings: [c.rating || 0]})
                } else {
                    avgRatings[avgRatings.findIndex(cat => cat.name === c.name)].ratings.push(c.rating || 0)
                }
            })
        })
  
        return avgRatings.map(c => ({id: c.id, name: c.name, rating: Math.round((c.ratings.reduce((a, b) => a + b, 0)/c.ratings.length) * 10) / 10}))
      },
      overallRating() {
        let overallRating = Math.round((this.averageRatings.reduce((a, b) => a + b.rating, 0)/this.averageRatings.length) * 10) / 10
        overallRating = overallRating.toString()
        return overallRating
      }
    },
}