import { loadingController } from '@ionic/vue';

export default {
    data() {
        return {
          loading: null,
        }
    },
    methods: {
        async startLoader() {
          this.loading = await loadingController
          .create({
          message: 'Bitte warten...',
          duration: 600000,
          });
          await this.loading.present()
        },
        async stopLoader() {
          if(this.loading && this.loading.dismiss) {
          await this.loading.dismiss()
          } else {
            setTimeout(() => {
              this.stopLoader()
            }, 100)
          }
        },
    }
}