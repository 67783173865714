<template>
    <div>
        <div class="my-profile-card">
            <ion-icon class="edit-profile" v-if="isMyProfile" :icon="createOutline" @click="$router.push({name: 'EditProfile'})"/>
            <div class="profile-image" v-if="userImage" @click="$store.commit('setFullscreen', {index: 0, photos: [userImage], hideCounter: true})">
                <div class="img-wrapper" :style="{background: 'url('+userImage+')', backgroundSize: 'cover', backgroundPosition: 'center'}">
                </div>
            </div>
            <div class="profile-image" v-else>
                <span>{{userInitials}}</span>
            </div>
            <div class="right">
                <span class="name">{{userName}}</span>
                <small class="who-and-where" v-if="userJobTitle">{{userJobTitle}}</small>
                <div class="rating" v-if="numberOfReviews" @click="setActiveTab('reviews')">
                    <div class="stars">
                        <ion-icon v-for="s in 5" :key="s" :icon="starConfig(s)"/>
                    </div>
                    <span class="rating-average" v-if="numberOfReviews">
                        {{overallRating && overallRating.length === 1 ? overallRating+',0' : overallRating.replace('.', ',')}}
                    </span>
                </div>
                <ion-button v-if="!numberOfReviews && isMyProfile" @click="$router.push({name: 'RequestReview'})">Bewertung einholen</ion-button>
                <small @click="(isMyProfile || numberOfReviews > 0) && setActiveTab('reviews')" class="number-of-reviews" :style="{marginTop: !isMyProfile && numberOfReviews === 0 ? '7px' : ''}">{{numberOfReviews}} {{numberOfReviews === 1 ? 'Bewertung' : 'Bewertungen'}}</small>
            </div>
        </div>
        <div class="description" v-if="!isMyProfile || !profileEmpty">
            <span>Beschreibung</span>
            <p v-if="userDescription">{{userDescription}}</p>
            <p v-else class="placeholder">Keine Beschreibung angegeben.</p>
        </div>
        <div class="competences" v-if="!isMyProfile || !profileEmpty">
            <span>Kompetenzen</span>
            <ion-chip disabled="true" v-for="competence in userCompetences" :key="competence.id">
                <ion-label color="primary">{{competence.name}}</ion-label>
            </ion-chip>
            <p v-if="(userCompetences && !userCompetences.length) || !userCompetences" class="placeholder">Keine Kompetenzen angegeben.</p>
        </div>
        <div class="education" v-if="!isMyProfile || !profileEmpty">
            <span>Ausbildungen</span>
            <p v-if="userEducation">{{userEducation}}</p>
            <p v-else class="placeholder">Keine Ausbildung angegeben.</p>
        </div>
        <noProfileData v-if="profileEmpty && isMyProfile"/>
    </div>
</template>

<style lang="sass" scoped>
.my-profile-card
    background: var(--indigo-medium)
    border-radius: 5px
    padding: 22px 23px 21px
    display: flex
    position: relative
    .edit-profile
        cursor: pointer
        position: absolute
        right: 15px
        top: 15px
        font-size: 23px
        color: #fff
    .profile-image
        min-width: 100px
        height: 100px
        position: relative
        display: flex
        justify-content: center
        align-items: center
        background: var(--indigo-lighter)
        border-radius: 50%
        .img-wrapper
            width: 100%
            height: 100%
            overflow: hidden
            border-radius: 50%
            cursor: pointer
        img
            object-fit: cover
            min-width: 100%
            min-height: 100%
        span
            position: absolute
            top: -2px
            right: 0
            left: 0
            bottom: 0
            margin: auto
            text-align: center
            height: 30%
            font-size: 28px
            color: var(--indigo-medium)
            letter-spacing: 2px
    .right
        margin-left: 15px
        margin-right: 25px
        .name, .who-and-where, .rating, .number-of-reviews
            color: white
            display: block
        .name
            font-size: 18px
            @media (max-width: 390px)
                font-size: 16px
            font-weight: 700
            line-height: 25px
            letter-spacing: 1px
            margin-bottom: 4px
        .who-and-where
            font-size: 14px
            font-weight: 500
            line-height: 16px
            letter-spacing: .5px
        .rating
            display: flex
            align-items: center
            margin: 16px 0 4px
            flex-wrap: wrap
            position: relative
            .stars
                margin-right: 3px
                ion-icon
                    font-size: 19.5px
                    margin-right: 7px
                    @media (max-width: 365px)
                        font-size: 17px
                        margin-right: 5px
            span
                position: absolute
                right: -27px
                font-size: 16px
                @media (max-width: 365px)
                    font-size: 14px
                    right: -20px
                    bottom: 2px
                line-height: 19px
                font-weight: 700
                letter-spacing: 1px
        ion-button
            min-width: 0 !important
            --background: var(--indigo-light)
            --box-shadow: none
            margin: 12px 0 6px
        .number-of-reviews
            cursor: pointer
            font-size: 12px
            font-weight: 400
            line-height: 20px

.description, .competences, .education
    margin: 30px 0
    span
        display: block
        color: var(--dim-gray-dark)
        font-weight: 700
        line-height: 16px
        font-size: 13px
        margin-right: auto
        text-transform: uppercase
        margin-bottom: 17px

.description, .competences, .education
    p
        white-space: pre-wrap
        display: block
        color: var(--dim-gray-dark)
        margin: 0
        font-size: 13px
        font-weight: 400
        line-height: 20px
        letter-spacing: 0em
    .placeholder
        margin-top: -6px
        font-style: italic

.competences
    ion-chip
        --background: var(--indigo-lighter)
        --color: var(--indigo-dark)
        font-size: 13px
        line-height: 20px
        font-weight: 400
        padding: 12px 20px
        height: 15px
        margin: 0 8px 8px 0
        opacity: 1
</style>

<script>
import { IonIcon, IonChip, IonLabel, IonButton } from '@ionic/vue'
import { createOutline } from 'ionicons/icons'
import noProfileData from '@/components/general/noProfileData.vue'

export default {
    props: ['user', 'numberOfReviews', 'starConfig', 'overallRating', 'setActiveTab', 'worker'],
    components: {IonIcon, IonChip, IonLabel, IonButton, noProfileData},
    data() {
        return {
            userData: null,
        }
    },
    async mounted() {
        this.userData = this.worker || this.user
    },
    watch: {
        user(u) {
            if(u && !this.worker) {
                this.userData = u
            }
        },
    },
    computed: {
        isMyProfile() {
            return !this.worker
        },
        profileEmpty() {
            return !this.userEducation && !this.userDescription && (!this.userCompetences || (this.userCompetences && !this.userCompetences.length))
        },
        userImage() {
            return this.userData && this.userData.photo
        },
        userName() {
            return this.userData && this.userData.firstname && this.userData.lastname && this.userData.firstname + ' ' + this.userData.lastname || 'Flurin Müller'
        },
        userInitials() {
            return this.userData && this.userData.firstname && this.userData.lastname && (this.userData.firstname[0]+this.userData.lastname[0]) || 'FM'
        },
        userJobTitle() {
            return this.userData && this.userData.jobtitle
        },
        userDescription() {
            return this.userData && this.userData.profile
        },
        userEducation() {
            return this.userData && this.userData.education
        },
        competences() {
            return this.$store.state.competences
        },
        userCompetences() {
            return this.userData && this.userData.competences && this.competences && this.userData.competences.filter(c => this.competences[c.id]).map(c => ({id: c.id, name: this.competences[c.id].name}))
        }
    },
    setup() {
        return { createOutline }
    }
}
</script>