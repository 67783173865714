<template>
    <div class="screenHeader">
       <div class="left">
            <div class="screenHeader-back" @click="$router.push({name: 'Dashboard', query: {keepData: true}})" v-if="showBackArrow">
                <ion-icon :icon="chevronBackOutline"/>
            </div>
           <span>{{headerTitle}}</span>
       </div>
       <div class="notifications" @click="$router.push({name: 'Activities'})">
        <ion-icon :icon="notificationsOutline" />
        <div class="has-new-notifications" v-if="hasNewActivities"></div>
       </div>
       <div class="profile-wrapper" @click="showDropdown = !showDropdown" v-click-away="closeDropdown">
        <div v-if="userImage" class="profile-img-wrapper" :style="{background: 'url('+userImage+')', backgroundSize: 'cover', backgroundPosition: 'center'}">
        </div>
        <div v-if="!userImage" class="profile-img-wrapper">
            <div class="profile-placeholder">
                <span>{{userInitials}}</span>
            </div>
        </div>
        <transition name="fade">
            <div class="profile-dropdown" v-if="showDropdown">
                <div class="arrow"></div>
                <div class="profile-item ion-activatable ripple-parent" @click="$router.push({name: 'MyProfile'})">
                    <ion-icon :icon="personOutline"/>
                    <span>Mein Profil</span>
                    <ion-ripple-effect></ion-ripple-effect>
                </div>
                <div class="profile-item ion-activatable ripple-parent" @click="logout">
                    <ion-icon :icon="logOutOutline"/>
                    <span>Logout</span>
                    <ion-ripple-effect></ion-ripple-effect>
                </div>
            </div>
        </transition>
       </div>
    </div>
</template>

<style lang="sass" scoped>
.screenHeader
    background: #fff
    box-sizing: border-box
    display: flex
    align-items: flex-end
    padding: 30px 5vw 15px
    .left
        display: flex
        margin-right: auto
        .screenHeader-back
            font-size: 20px
            position: relative
            right: 6px
            cursor: pointer
        span
            color: var(--dim-gray-dark)
            font-weight: 700
            line-height: 16px
            margin-top: 2.5px
            font-size: 14px
            margin-right: auto
            text-transform: uppercase
    .notifications
        width: 23px
        height: 23px
        margin-right: 12px
        position: relative
        cursor: pointer
        ion-icon
            color: var(--dim-gray-light)
            font-size: 23px
        .has-new-notifications
            border-radius: 50%
            border: 3px solid white
            background: var(--indian-red)
            width: 13px
            height: 13px
            position: absolute
            top: -2px
            right: 0
    .profile-wrapper
        position: relative
        cursor: pointer
        .profile-img-wrapper
            width: 50px
            height: 50px
            border-radius: 50%
            overflow: hidden
            .profile-placeholder
                width: 100%
                height: 100%
                display: block
                cursor: pointer
                position: relative
                background: var(--indigo-lighter)
                position: relative
                display: flex
                justify-content: center
                align-items: center
                span
                    position: absolute
                    top: 0
                    right: -2px
                    left: 0
                    bottom: 0
                    margin: auto
                    text-align: center
                    height: 30%
                    font-size: 14px
                    color: var(--indigo-medium)
                    letter-spacing: 2px
        .profile-dropdown
            position: absolute
            z-index: 100
            top: 70px
            right: 0
            background: var(--dim-gray-dark)
            padding: 5px 0
            box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px
            border-radius: 10px
            cursor: pointer
            .arrow
                width: 15px
                height: 15px
                background: var(--dim-gray-dark)
                transform: rotateZ(45deg)
                position: absolute
                z-index: 1
                right: 18px
                top: -7px
            .profile-item
                position: relative
                z-index: 2
                width: 150px
                padding: 10px 15px
                display: flex
                ion-icon
                    font-size: 20px
                    color: white
                span
                    margin-left: 10px
                    font-size: 16px
                    font-weight: 500
                    text-transform: none
                    color: white
</style>

<script>
import { IonIcon, IonRippleEffect } from '@ionic/vue'
import { notificationsOutline, chevronBackOutline, personOutline, logOutOutline } from 'ionicons/icons'
import { firebase } from '@/firebase'
import activities from '@/mixins/activities'

export default {
    props: ['headerTitle', 'showBackArrow'],
    mixins: [activities],
    components: { IonIcon, IonRippleEffect },
    data() {
        return {
            showDropdown: false,
            userInitials: 'FM',
            userImage: null
        }
    },
    mounted() {
        this.fetchUser()
    },
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    watch: {
        user() {
            this.fetchUser()
        },
    },
    methods: {
        fetchUser() {
            if(this.user) {
                this.userInitials = this.user && this.user.firstname && this.user.lastname && (this.user.firstname[0]+this.user.lastname[0]) || 'FM'
                this.userImage = this.user && this.user.photo
            }
        },
        closeDropdown() {
            this.showDropdown = false
        },
        logout() {
            firebase.auth().signOut().then(() => {
                this.$store.commit('setUser', null)
                this.$router.push({name: 'Login'})
            })
        }
    },
    setup() {
        return { notificationsOutline, chevronBackOutline, personOutline, logOutOutline }
    }
}
</script>