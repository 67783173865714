<template>
  <div class="send-sms-wrapper">
      <img src="@/assets/illustration.svg" class="worker">
      <div class="send-sms-form">
          <div class="input-number-wrapper">
              <!-- <customSelect :options="['+41', '+49', '+385']"/> -->
              <span>{{countryCode}}</span>
              <input type="number" min="0" inputmode="numeric" pattern="[0-9]*" placeholder="Mobile-Nummer eingeben" v-model="phoneNumber">
          </div>
          <button id="sign-in-button" class="button">Weiter</button>
      </div>
  </div>
</template>

<style lang="sass" scoped>
.send-sms-wrapper
  .worker
    margin: 32px 0 20px 0
  .send-sms-form 
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    .input-number-wrapper, .button
      width: 300px
      margin-bottom: 10px
      flex-grow: 0
    .input-number-wrapper
      display: flex
      align-items: center
      border-bottom: 1px solid var(--silver-light)
      margin-bottom: 17px
      input[type="number"]
        width: 250px
        padding: 10px
        border: 0
        outline: 0
        -webkit-appearance: none
        -moz-appearance: textfield
        font-size: 15px
        &::-webkit-inner-spin-button, &::-webkit-outer-spin-button
          -webkit-appearance: none
          margin: 0
      span
        margin-right: 7px
        margin-bottom: 2px
    .button
      background-color: var(--indigo-medium)
      color: #fff
      padding: 12px
      font-size: 13px
      outline: 0
      border-radius: 4px
</style>

<script>
import firebase from 'firebase/app'
import toast from '@/mixins/toast'
import loader from '@/mixins/loader'
import pn from 'awesome-phonenumber'
import checkIfWorkerExists from '@/services/checkIfWorkerExists'

export default {
  mixins: [toast, loader],
  data() {
    return {
      countryCode: '',
      phoneNumber: '',
    }
  },
  mounted() {
    this.countryCode = this.$route.query.phone ? '+'+pn(decodeURIComponent(this.$route.query.phone)).getCountryCode() : '+41'
    this.phoneNumber = this.$route.query.phone ? parseInt(decodeURIComponent(this.$route.query.phone).replace(this.countryCode, '')) : ''
    this.initRecaptcha() 
    
    window.analytics.track('Login Step 1')
  },
  methods: {
    initRecaptcha() {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
        size: 'invisible',
        callback: () => {
          this.sendMsgWithCode()
        }
      });
      window.recaptchaVerifier.render();
    },
    resetRecaptcha() {
      window.recaptchaVerifier.render().then(function(widgetId) {
        window.grecaptcha.reset(widgetId);
      })
    },
    async sendMsgWithCode() {
      const phoneNumber = (this.countryCode.toString()+(this.phoneNumber.toString().replace(/\s/g,''))).toString()
      if(!pn(phoneNumber).isValid()) {
        this.openToast('Diese Telefonnummer hat ein ungültiges Format.', 6000)
        this.resetRecaptcha()
        return
      }

      this.startLoader()

      const checkPhone = await checkIfWorkerExists(phoneNumber)

      const { workerExists } = await checkPhone.json()

      if(!workerExists) {
        this.stopLoader()
        this.openToast('Diese Telefonnummer ist für die Anmeldung bei WeBuild nicht zugelassen. Bitte stelle sicher, dass du die gleiche Telefonnummer aus der Einladung verwendest oder kontaktiere unseren Support unter +41795489656.', 12000)
        this.resetRecaptcha()
        window.analytics.track('Login Step 1 Phone number not existing')
        return
      }

      firebase.auth().signInWithPhoneNumber(phoneNumber, window.recaptchaVerifier)
      .then(confirmationResult => {
        this.stopLoader()
        window.confirmationResult = confirmationResult
        this.$emit('set-phone', phoneNumber)
        window.analytics.track('Login Step 2 Verification Code Sent')
        this.$emit('set-step', 1)
      }).catch(error => {
        this.stopLoader()
        this.openToast(error.message, 7000)
        this.resetRecaptcha()
      });
    }
  },
}
</script>