<template>
  <ion-page>
    <ion-content>
        <div class="add-reference-header">
          <div class="first-step" v-if="step === 1">
            <div class="add-reference-header-action" @click="$router.back()">
              <ion-icon :icon="closeOutline"/>
            </div>
            <div class="content">
              <span>Referenzprojekt erfassen</span>
              <small>Fotos auswählen</small>
            </div>
            <span class="next" @click="goToNextStep">Weiter</span>
          </div>
          <div class="second-step" v-if="step === 2">
            <div class="add-reference-header-action" @click="step = 1">
              <ion-icon :icon="chevronBackOutline"/>
            </div>
            <div class="content">
              <span>Referenzprojekt erfassen</span>
              <small>Fotos auswählen</small>
            </div>
            <span class="next add-reference-header-action" @click="addReference">Fertig</span>
          </div>
        </div>
        <div class="add-reference">
          <div class="first-step" v-show="step === 1">
            <div class="add-photo" @click="addPhoto" v-if="!photos.length">
              <img src="@/assets/photo-icon.svg">
              <span>Fotos auswählen</span>
              <small>Klicke hier, um die gewünschten Fotos auszuwählen.</small>
            </div>
            <input
              ref="addPhoto"
              type="file"
              accept="image/jpeg, image/png, image/gif"
              placeholder="Choose photos or drop them here..."
              @change="photoInputChanged($event)"
              multiple
              hidden
            />
            <div v-if="photos.length">
              <draggable v-model="photos" :move="preventChangingPositionOfAddButton" class="selected-images-add" handle=".img-wrapper-drag-add">
                <div v-for="(photo, index) in photos" class="img-wrapper img-wrapper-drag-add" :key="index+'-add'" :style="{background: 'url('+photo.preview+')', backgroundSize: 'cover', backgroundPosition: 'center'}">
                  <ion-icon class="img-remove" :icon="closeOutline" @click.stop="photos.splice(index, 1)"/>
                </div>
                <div class="img-wrapper img-wrapper-add" @click="addPhoto">
                  <ion-icon class="img-add" :icon="addOutline"/>
                </div>
              </draggable>
              <small class="add-photo-msg">Hinweis: Bleibe mit dem Daumen länger auf einem Foto, um die Darstellungsreihenfolge zu ändern.</small>
            </div>
          </div>
          <div class="second-step" v-show="step === 2">
            <div class="form-field">
              <label>PROJEKT</label>
              <input v-model="title" placeholder="Projektname">
            </div>
            <div class="form-field">
              <label>BENÖTIGTE KOMPETENZEN</label>
              <ion-select cancel-text="Zurück" ok-text="Fertig" @ionChange="setReferenceCompetences" v-model="referenceCompetences" placeholder="Kompetenzen auswählen" multiple>
                <ion-select-option v-for="(competence, index) in competences" :key="index" :value="index">{{competence.name}}</ion-select-option>
              </ion-select>
            </div>
            <div class="form-field">
              <label>FERTIGSTELLUNGSDATUM</label>
              <ion-datetime @ionChange="setReferenceDate" :value="getReferenceDate(referenceDate)" cancelText="Zurück" doneText="Fertig" display-format="DDDD, DD. MMMM YYYY" day-names="Montag, Dienstag, Mittowoch, Donnerstag, Freitag, Samstag, Sonntag" month-names="Januar, Februar, März, April, Mai, Juni, Juli, August, September, Oktober, November, Dezember"></ion-datetime>
            </div>
            <div class="form-field">
              <label>BESCHREIBUNG</label>
              <autoexpand-textarea v-model:value="description" placeholder="Text eingeben" />
           </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonIcon, IonSelect, IonSelectOption, IonDatetime } from '@ionic/vue'
import generateUUID from '@/utils/generateUUID'
import { closeOutline, addOutline, chevronBackOutline } from 'ionicons/icons'
import AutoexpandTextarea from '@/components/general/autoexpandTextarea'
import toast from '@/mixins/toast'
import loader from '@/mixins/loader'
import activities from '@/mixins/activities'
import fetchingAndGettingGlobalData from '@/mixins/fetchingAndGettingGlobalData'
import photos from '@/mixins/photos'
import { referencesCollection } from '@/firebase'
import { VueDraggableNext } from 'vue-draggable-next'

export default {
  components: { IonPage, IonContent, IonIcon, IonSelect, IonSelectOption, draggable: VueDraggableNext, AutoexpandTextarea, IonDatetime },
  mixins: [toast, loader, fetchingAndGettingGlobalData, photos, activities],
  data() {
    return {
      title: '',
      description: '',
      photos: [],
      referenceCompetences: [],
      referenceDate: new Date(),
      step: 1
    }
  },
  setup() {
    return { closeOutline, addOutline, chevronBackOutline }
  },
  async mounted() {
    // Get competences
    await this.getCompetences()

    let segment = {}
    if(this.user && this.user.segment) {
      try {
          segment = JSON.parse(this.user.segment)
      } catch (e) {
          console.log(e)
      }
    }

    window.analytics.track('Reference Add Step 1', {
      name: this.user && this.user.firstname + ' ' + this.user.lastname,
      jobtitle: this.user && this.user.jobtitle,
      ...segment
    })
  },
  watch: {
    $route(r) {
      setTimeout(() => {
        if(r.name !== 'AddReference') {
          this.resetForm()
        }
      }, 500)
    },
    step(v) {
      if(v === 2) {

        let segment = {}
        if(this.user && this.user.segment) {
          try {
              segment = JSON.parse(this.user.segment)
          } catch (e) {
              console.log(e)
          }
        }

        window.analytics.track('Reference Add Step 2', {
          name: this.user && this.user.firstname + ' ' + this.user.lastname,
          jobtitle: this.user && this.user.jobtitle,
          ...segment
        })
      }
    }
  },
  methods: {
    preventChangingPositionOfAddButton({relatedContext}){
      return !!relatedContext.element;
    },
    resetForm() {
      this.title = ''
      this.description = ''
      this.referenceCompetences = []
      this.referenceDate = new Date()
      this.photos = []
      this.step = 1
    },
    async addReference() {
      if(!this.title.trim()) {
        this.openToast('Bitte gib einen Projektnamen.', 6000)
        return
      }

      await this.startLoader()

      // Add reference to database
      const referenceRef = await referencesCollection.add({
        project: this.title,
        description: this.description,
        createdOn: new Date(),
        updatedOn: new Date(),
        date: this.referenceDate,
        worker: this.user.id,
        competences: this.referenceCompetences,
        photos: []
      })

      await this.addActivity({
        type: 'referenceAdded',
        createdOn: new Date(),
        referenceId: referenceRef.id,
        referenceName: this.title,
      })

      // Upload photos
      const photoURLs = await this.uploadPhotos(this.photos, referenceRef.id, this.user.token)

      // Update reference with photos
      await referencesCollection.doc(referenceRef.id).update({ photos: photoURLs })

      let segment = {}
       if(this.user && this.user.segment) {
         try {
            segment = JSON.parse(this.user.segment)
         } catch (e) {
            console.log(e)
         }
      }

      window.analytics.track('Reference Added', {
        name: this.user && this.user.firstname + ' ' + this.user.lastname,
        jobtitle: this.user && this.user.jobtitle,
        ...segment,
        photoCount: photoURLs.length || 0
      })

      this.$router.push({name: 'Dashboard'})
      await this.stopLoader()
    },
    setReferenceCompetences(c) {
      this.referenceCompetences = c.target.value
    },
    getReferenceDate(referenceDate) {
      referenceDate = referenceDate || new Date()
      return new Date(referenceDate.getTime()).toDateString()
    },
    setReferenceDate(d) {
      this.referenceDate = d && d.target && d.target.value && new Date(d.target.value) || new Date()
    },
    goToNextStep() {
      if(!this.photos.length) {
        this.openToast('Bitte wähle mindestens ein Foto aus.', 6000)
        return
      }

      this.step = 2
    },
    addPhoto() {
      this.$refs.addPhoto.click()
    },
    photoInputChanged(e) {
      const files = e.target.files

      files.forEach(file => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.addEventListener("load", () => {
          const photo = {id: generateUUID(), file: file, preview: fileReader.result}
          this.photos.push(photo)
        });
      })
      this.$refs.addPhoto.value = ''
    }
  }
}
</script>

<style lang="sass" scoped>
.add-reference-header
  .second-step
    .content
      small
        color: var(--dim-gray-dark)
  .first-step, .second-step
    .add-reference-header-action
      display: flex
      ion-icon
        cursor: pointer
        font-size: 22px
        font-weight: 700
    display: flex
    justify-content: center
    padding: 52px 23px 10px
    background: var(--dim-gray-dark)
    color: white
    text-align: center
    .content
      margin: 0 auto
      span, small
        display: block
      span
        font-size: 18px
        font-weight: 700
        line-height: 21px
        margin-bottom: 2px
      small
        font-size: 12px
        line-height: 14px
        font-weight: 700
    .next
      cursor: pointer
      white-space: nowrap
      margin-top: 2px
      font-size: 14px
      text-align: right
      line-height: 16px
      font-weight: 700

.add-reference-header-action
  width: 40px

.add-reference
  .first-step
    .add-photo
      margin: 30px
      padding: 35px
      border: 1px solid var(--silver-light)
      border-radius: 5px
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      text-align: center
      img
        width: 69px
        height: auto
        margin-bottom: 17px
      span
        font-weight: 700
        font-size: 18px
        line-height: 21px
        color: var(--indigo-medium)
        margin-bottom: 6px
      small
        line-height: 20px
        font-size: 14px
        font-weight: 500
        color: var(--dim-gray-light)
    .selected-images-add
      display: flex
      flex-wrap: wrap
      margin: 20px
      .img-wrapper
        width: calc(33% - 22px)
        height: calc(33vw - 33px)
        margin: 11px
        border-radius: 5px
        background: var(--silver-light)
        position: relative
        .img-remove
          position: absolute
          z-index: 20
          top: -1px
          right: -1px
          color: var(--dim-gray-light)
          padding: 3px
          background: white
          border-bottom-left-radius: 5px
          font-size: 14px
      .img-wrapper-add
        background: white
        border: 1px dashed var(--silver-light)
        .img-add
          position: absolute
          left: 0
          right: 0
          top: 0
          bottom: 0
          margin: auto
          color: var(--silver-dark)
          font-size: 26px
    .add-photo-msg
      color: var(--silver-dark)
      font-size: 14px
      font-weight: 400
      line-height: 20px
      text-align: center
      display: block
      margin: 0 35px
  .second-step
    margin: 16px 0
    .form-field
      display: flex
      flex-direction: column
      padding: 16px 31px
      label
        text-transform: uppercase
        font-size: 12px
        line-height: 14px
        font-weight: 700
        margin-bottom: 4px
        color: var(--dim-gray-light)
      input, ion-select, ion-datetime, textarea
        resize: none
        outline: 0
        border: 0
        border-bottom: 1px solid #E9EAEC
        font-size: 15px
        padding: 10px 0
        color: var(--dim-gray-dark)
        &::placeholder
          color: var(--dim-gray-dark)
      input
        width: 100%
        -webkit-appearance: none
        -moz-appearance: textfield
        &::-webkit-inner-spin-button, &::-webkit-outer-spin-button
          -webkit-appearance: none
          margin: 0
      ion-select
        --placeholder-opacity: 1
</style>