<template>
    <div class="spinner-wrapper">
        <ion-spinner></ion-spinner>
    </div>
</template>

<script>
import {IonSpinner} from '@ionic/vue'
export default {
    components: {IonSpinner}
}
</script>

<style lang="sass" scoped>
.spinner-wrapper
    color: var(--indigo-dark)
    border-radius: 50%
    display: flex
    justify-content: center
    align-items: center
    width: 50px
    height: 50px
    background: #fff
    position: fixed
    z-index: 10000
    top: 50px
    left: 0
    right: 0
    margin: auto
    box-shadow: rgba(100, 100, 111, 0.5) 0px 7px 29px 0px
    transform: scale(0.7)
</style>