<template>
    <div :class="{noVerifiedReviews}">
        <noReviews v-if="!myReviews.length"/>
        <div class="reviews-summary" v-if="!noVerifiedReviews && myReviews.length">
            <div class="header">
                <div class="top">
                    <span>Gesamtbewertung</span>
                    <small>{{numberOfReviews}} {{numberOfReviews === 1 ? 'Bewertung' : 'Bewertungen'}}</small>
                </div>
                <div class="rating">
                    <div class="stars">
                        <ion-icon v-for="s in 5" :key="s" :icon="starConfig(s)"/>
                    </div>
                    <span class="rating-average">
                        {{overallRating.length === 1 ? overallRating+',0' : overallRating.replace('.', ',')}}
                    </span>
                </div>
            </div>
            <div class="content">
                <div class="skill" v-for="skill in averageRatings" :key="skill.id">
                    <span>{{skill.name}}</span>
                    <div class="rating">
                        <div class="rating-visual">
                            <div class="rating-inside" :style="{width: (skill.rating * 20) + '%'}">
                            </div>
                        </div>
                        <small>{{skill.rating.toString().replace('.', ',')}}</small>
                    </div>
                </div>
            </div>
        </div>
        <review v-for="(review, index) in myReviews" :key="review.id" :review="review" :averageRatingPerReview="averageRatingPerReview" :isLastRating="index === myReviews.length-1" :showSeparator="index !== myReviews.length - 1"/>
    </div>
</template>

<style lang="sass" scoped>
.noVerifiedReviews
    margin-top: -15px

.reviews-summary
    .header
        border: 1px solid var(--silver-light)
        padding: 18px 18px 14px
        border-top-left-radius: 5px
        border-top-right-radius: 5px
        .top
            display: flex
            align-items: center
            margin-bottom: 12px
            span
                display: block
                color: var(--dim-gray-dark)
                font-weight: 700
                line-height: 16px
                font-size: 13px
                margin-right: auto
                text-transform: uppercase
            small
                color: var(--dim-gray-light)
                line-height: 20px
                font-weight: 400
                font-size: 12px
        .rating
            display: flex
            align-items: center
            margin: 16px 0 4px
            flex-wrap: wrap
            color: var(--indigo-medium)
            .stars
                margin-right: 3px
                ion-icon
                    font-size: 19.5px
                    margin-right: 7px
            span
                font-size: 16px
                font-weight: 700
                line-height: 19px
                letter-spacing: 1px
    .content
        border: 1px solid var(--silver-light)
        border-top: 0
        padding: 22px 18px 12px
        border-bottom-left-radius: 5px
        border-bottom-right-radius: 5px
        .skill
            display: flex
            align-items: center
            justify-content: space-between
            margin-bottom: 15px
            span
                margin: 0
                color: var(--dim-gray-dark)
                font-size: 13px
                font-weight: 400
                text-transform: none
            .rating
                display: flex
                align-items: center
                padding-left: 10px
                .rating-visual
                    // width: 133px
                    width: 104px
                    @media (max-width: 370px)
                        width: 90px
                    height: 7px
                    background: var(--silver-lighter)
                    border-radius: 15px
                    overflow: hidden
                    .rating-inside
                        background: var(--indigo-medium)
                        height: 100%
                small
                    width: 15px
                    text-align: center
                    display: block
                    margin-left: 20px
                    font-size: 11px
                    color: var(--dim-gray-dark-2)
                    font-weight: 700
                    white-space: nowrap
</style>

<script>
import review from '@/components/profile/review'
import noReviews from '@/components/general/noReviews.vue'
import { IonIcon } from '@ionic/vue'

export default {
    props: ['myReviews', 'starConfig', 'averageRatings', 'overallRating', 'numberOfReviews', 'isMyProfile'],
    components: {review, noReviews, IonIcon},
    computed: {
        noVerifiedReviews() {
            return !this.myReviews.filter(r => r.verified).length && this.myReviews.length
        }
    },
    data() {
        return {
            showDropdown: false
        }
    }
}
</script>