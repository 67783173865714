<template>
  <ion-page>
    <div ref="profileWrapper" class="profile-wrapper">
    <!-- <ion-content :scroll-events="true" @ionScroll="loadMoreReferences($event)" ref="profileWrapper" class="profile-wrapper"> -->
      <!-- <ion-refresher slot="fixed" @ionRefresh="fetchData($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher> -->
      <transition name="slide-up">
        <loadingTop v-if="refreshLoader"/>
      </transition>
      <screenHeader :headerTitle="!worker ? 'Mein Profil' : 'Profil von '+ worker.firstname + ' ' + worker.lastname" :showBackArrow="!!worker"/>
      <profileTabs v-if="!loading" :isMyProfile="!worker" :activeTab="activeTab" :setActiveTab="tab => activeTab = tab" :numberOfReviews="numberOfVerifiedReviews" :numberOfReferences="myReferences.length"/>
      <div class="tabs-content-wrapper" v-if="!screenLoading">
        <references v-if="activeTab === 'references'" :isMyProfile="!worker" :user="user" :competences="competences" :myReferences="myReferences" @set-likes="setLikes($event)" @delete-reference="deleteReference($event)" :refreshLoader="refreshLoader"/>
        <reviews v-if="activeTab === 'reviews'" :isMyProfile="!worker" :myReviews="myReviews" :numberOfReviews="numberOfVerifiedReviews" :starConfig="starConfig" :overallRating="overallRating" :averageRatings="averageRatings"/>
        <myProfile v-if="activeTab === 'profile'" :worker="worker" :setActiveTab="tab => activeTab = tab" :user="user" :numberOfReviews="numberOfVerifiedReviews" :starConfig="starConfig" :overallRating="overallRating"/>
      </div>
      <ion-spinner v-if="(loading && !myReferences.length) || refreshLoader || screenLoading"></ion-spinner>
    <!-- </ion-content> -->
    </div>
  </ion-page>
</template>

<script>
import { referencesCollection, workersCollection } from '@/firebase'
import { IonPage, IonSpinner, /*IonContent, IonRefresher, IonRefresherContent,*/ } from '@ionic/vue'
import screenHeader from '@/components/general/screenHeader'
import profileTabs from '@/components/profile/profileTabs'
import myProfile from '@/components/profile/myProfile'
import reviews from '@/components/profile/reviews'
import references from '@/components/profile/references'
import loadingTop from '@/components/dashboard/loadingTop'
import fetchingAndGettingGlobalData from '@/mixins/fetchingAndGettingGlobalData'
import reviewsMixin from '@/mixins/reviews'
import photos from '@/mixins/photos'
import toast from '@/mixins/photos'

export default {
  mixins: [fetchingAndGettingGlobalData, reviewsMixin, photos, toast],
  components: { IonPage, screenHeader, profileTabs, myProfile, reviews, references, IonSpinner, loadingTop, /*IonContent, IonRefresher, IonRefresherContent,*/ },
  data() {
    return {
      activeTab: 'profile',
      initialLoading: true,
      loading: true,
      refreshLoader: false,
      lastLoadedDocument: null,
      myReferences: [],
      screenLoading: true,
      worker: null
    }
  },
  async mounted() {
    this.activeTab = this.$route.query.tab || 'profile'
    this.$router.push({query: {tab: this.activeTab}})
    await this.fetchData()
    setTimeout(() => {
      if(this.$refs.profileWrapper) {
        this.$refs.profileWrapper.addEventListener('scroll', async e => {
          // Refetch data when user scrolls to top
          if(e.target.scrollTop === 0) {
            this.refreshLoader = true
            await this.fetchData()
            this.refreshLoader = false
          }
          // Pagination
          if(((e.target.scrollTop + document.body.scrollTop) / (e.target.scrollHeight - e.target.clientHeight) * 100) > 95) {
            await this.getMyReferences()
          }
        })
      }
    }, 250)
  },
  watch: {
    $route(r, oldR) {

      if((r.name === 'Profile' || r.name === 'MyProfile') && r.name !== oldR.name) {
        this.resetvalues()
        this.activeTab = oldR.name === 'ReferenceComments' ? 'references' : this.$route.query.tab || 'profile'
        this.fetchData()
      }

      if(r.name !== oldR.name && this.myReferences.length) {
        const newReferences = this.dashboardReferences.map(r => this.myReferences.find(myRef => myRef.id === r.id) || r)
        this.$store.commit('setDashboardReferences', newReferences)
      }
    },
    activeTab(t) {
      this.$router.push({query: {tab: t}})
    }
  },
  computed: {
    dashboardReferences() {
      return this.$store.state.dashboardReferences
    },
    numberOfVerifiedReviews() {
      return this.myReviews.filter(r => r.verified).length
    }
  },
  methods: {
    // async loadMoreReferences(ev) {
    //   let elem = this.$refs.profileWrapper

    //   if(!elem.getScrollElement) {
    //     elem = elem.$el
    //   }

    //   const scrollElement = await elem.getScrollElement()

    //   const scrollPosition = ev.detail.scrollTop;
    //   const totalContentHeight = scrollElement.scrollHeight;
    //   const viewportHeight = elem.offsetHeight;

    //   const percentage = scrollPosition / (totalContentHeight - viewportHeight);

    //   // Pagination
    //   if((percentage * 100) > 95) {
    //     await this.getMyReferences()
    //   }
    // },
    resetvalues() {
      // Reset values
      this.worker = null
      this.myReviews = []
      this.myReferences = []
      this.lastLoadedDocument = null
      this.initialLoading = true
      this.loading = true
      this.screenLoading = true
    },
    async getWorker() {
      const worker = await workersCollection.doc(this.$route.params.workerId).get()

      if (!worker.exists) {
        this.$router.push({name: 'Dashboard'})
        return
      }

      const workerData = { id: worker.id, ...worker.data() }

      if(workerData) {
        this.worker = workerData
      } else {
        this.openToast('Benutzer wurde nicht gefunden.', 6000)
        this.$router.push({name: 'Dashboard'})
      }
    },
    async fetchData(/*ev*/) {

      // if(ev && ev.target && ev.target.complete) {
      //   this.refreshLoader = true
      // }
      this.refreshLoader = true

      // If worker profile, fetch worker
      if(this.$route.params.workerId) {
        await this.getWorker()
      }

      // Get user data
      await this.getUser()
      
      // Get workers and competences to populate references
      await this.getCompetences()
      await this.getWorkers()

      await this.getMyReviews()
      await this.getMyReferences()

      setTimeout(() => {
        // if(ev && ev.target && ev.target.complete) {
          // this.refreshLoader = false
          // ev.target.complete()
        // }
        this.refreshLoader = false
        this.screenLoading = false
      }, 500)
    },
    async getMyReferences() {
      // If there's no more references or loading is already in progress and it's not initial loading
      if(this.lastLoadedDocument === 'ALL_REFERENCES_LOADED' || (this.loading && !this.initialLoading)) {
          return
      }

      // Don't lazy load references on scroll to bottom if the references tab is not selected
      if(!this.initialLoading && this.activeTab !== 'references') {
        return
      }

      this.initialLoading = false
      this.loading = true

      // Skip previously loaded references
      let snapshot
      if(this.lastLoadedDocument && !this.initialLoading) {
          snapshot = await referencesCollection.where('worker', '==', this.$route.params.workerId || this.user.id).orderBy('createdOn', 'desc').startAfter(this.lastLoadedDocument).limit(5).get()
      } else {
          snapshot = await referencesCollection.where('worker', '==', this.$route.params.workerId || this.user.id).orderBy('createdOn', 'desc').limit(5).get()
      }

      const references = snapshot.docs.map(doc => {
          const reference = { id: doc.id, ...doc.data() }
          reference.competences = reference.competences && reference.competences.length ? reference.competences.filter(c => this.competences && this.competences[c]).map(c => this.competences && this.competences[c]) : []
          reference.worker = this.worker || this.user
          return reference
      })

      this.myReferences = this.lastLoadedDocument && !this.refreshLoader ? [...this.myReferences, ...references] : [...references]
      this.lastLoadedDocument = snapshot.docs.length && this.myReferences.length >= 5 ? snapshot.docs[snapshot.docs.length-1] : 'ALL_REFERENCES_LOADED'

      this.loading = false
    },
    async deleteReference({referenceId, referencePhotos}) {
      if(this.$route.params.workerId || !referenceId) {
        return
      }

      const comments = await referencesCollection.doc(referenceId).collection('comments').get()

      for(let i = 0; i < comments.docs.length; i++) {
        if(comments && comments.docs && comments.docs[i] && comments.docs[i].id) {
          await referencesCollection.doc(referenceId).collection('comments').doc(comments.docs[i].id).delete()
        }
      }

      await referencesCollection.doc(referenceId).delete()
      this.deletePhotos(referencePhotos, referenceId)

      let segment = {}
       if(this.user && this.user.segment) {
         try {
            segment = JSON.parse(this.user.segment)
         } catch (e) {
            console.log(e)
         }
      }

      window.analytics.track('Reference Deleted', {
        name: this.user && this.user.firstname + ' ' + this.user.lastname,
        jobtitle: this.user && this.user.jobtitle,
        ...segment
      })

      this.myReferences = this.myReferences.filter(r => r.id !== referenceId)
    },
    setLikes({ likes, referenceId }) {
      this.myReferences.find(r => r.id === referenceId).likes = likes
    },
  }
}
</script>

<style lang="sass" scoped>
.profile-wrapper
  background: white
  overflow-y: scroll
  height: calc(100vh - 57px)
  padding-bottom: 20px

.tabs-content-wrapper
  padding: 23px 21px

ion-refresher
  position: absolute
  top: 50px

ion-spinner
  color: var(--indigo-dark)
  margin: 160px auto 0
  display: block
  transform: scale(0.85)
</style>